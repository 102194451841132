import { useActions, useTypedSelector } from '../../../../hooks'
import WhistleblowerPolicyPage from './WhistleblowerPolicyPage'
import IOIPolicy from './custom/IOIPolicy'
import MikkellerPolicy from './custom/MikkellerPolicy'
import DacarpoPolicy from './custom/DacarpoPolicy'
import AalborgTeaterPolicy from './custom/AalborgTeaterPolicy'
import VasikPolicy from './custom/VasikPolicy'
import XxlPolicy from './custom/XxlPolicy'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Spinner from '../../../layout/spinner/Spinner'
import OrisPolicy from './custom/OrisPolicy'

interface WhistleblowerPolicyResolverProps {}

const WhistleblowerPolicyResolver: React.FC<
    WhistleblowerPolicyResolverProps
> = () => {
    const { companyName, customWbPolicyName, whistleblowerLoading } =
        useTypedSelector((state) => state.whistleblower)

    const { whistleblowerLogin } = useActions()

    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        if (!companyName) {
            whistleblowerLogin(id)
        }

        // eslint-disable-next-line
    }, [companyName])

    const resolvePolicy = () => {
        switch (customWbPolicyName) {
            case 'ioi':
                return <IOIPolicy />
            case 'mikkeller':
                return <MikkellerPolicy />
            case 'dacarpo':
                return <DacarpoPolicy />
            case 'aalborg-teater':
                return <AalborgTeaterPolicy />
            case 'vasik':
                return <VasikPolicy />
            case 'xxl':
                return <XxlPolicy />
            case 'oris':
                return <OrisPolicy />
            default:
                return <WhistleblowerPolicyPage />
        }
    }

    return (
        <>
            {whistleblowerLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '90vh',
                    }}
                >
                    <Spinner />
                </div>
            ) : (
                <>
                    {!customWbPolicyName ? (
                        <WhistleblowerPolicyPage />
                    ) : (
                        resolvePolicy()
                    )}
                </>
            )}
        </>
    )
}

export default WhistleblowerPolicyResolver
