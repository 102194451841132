import produce from 'immer'
import { WhistleblowerActionType } from '../action-types'
import { WhistleblowerAction } from '../actions'
import { CountryInterface } from './countriesReducer'
import { MessageInterface } from '../../components/reusable/messages/Messages'

export type CustomPolicyName =
    | 'ioi'
    | 'mikkeller'
    | 'dacarpo'
    | 'aalborg-teater'
    | 'vasik'
    | 'xxl'
    | 'oris'

export interface ContactLineInterface {
    id: number
    first_name: string
    last_name: string
    email: string
    phone: {
        country_code: string
        phone: string
    }
    email_verified_at: string
    status: number
    country_id: number
    contact_information: {
        position: string
        priority: number
        note: string | null
        profile_img: string | null
        onboarding_token: string | null
    }
    languages: CountryInterface[]
}

interface WhistleblowerState {
    whistleblowerLoading: boolean
    organizationalCode: null | string
    companyName: null | string
    accountType: null | string
    isWhistleblowerLoggedIn: boolean
    whistleblowerError: null | string
    newReport: {
        id: null | number
        code: null | {
            whistleblower_code_diminished: string
            whistleblower_code_unhashed: string
        }
    }
    openReport: any
    messages: MessageInterface[]
    reportErrors: any
    reportingChannelLanguage: any
    isExcludeModeActive: boolean
    customWbPolicyName: null | CustomPolicyName
    policyContactLines: null | ContactLineInterface[]
}

const initialState: WhistleblowerState = {
    whistleblowerLoading: true,
    organizationalCode: null,
    companyName: null,
    accountType: null,
    isWhistleblowerLoggedIn: false,
    whistleblowerError: null,
    newReport: {
        id: null,
        code: null,
    },
    openReport: null,
    messages: [],
    reportErrors: null,
    reportingChannelLanguage: null,
    isExcludeModeActive: false,
    customWbPolicyName: null,
    policyContactLines: null,
}

const reducer = produce(
    (state: WhistleblowerState = initialState, action: WhistleblowerAction) => {
        switch (action.type) {
            case WhistleblowerActionType.WHISTLEBLOWER_LOGIN:
                state.whistleblowerLoading = false
                state.whistleblowerError = null
                state.isWhistleblowerLoggedIn = true
                state.organizationalCode = action.payload.organizationalCode
                state.companyName = action.payload.companyName
                state.accountType = action.payload.accountType
                state.isExcludeModeActive = action.payload.isExcludeModeActive
                state.customWbPolicyName = action.payload.customWbPolicyName
                state.policyContactLines = action.payload.policyContactLines

                return state

            case WhistleblowerActionType.CREATE_REPORT:
                state.whistleblowerLoading = false
                state.whistleblowerError = null
                state.reportErrors = null
                state.newReport = {
                    code: action.payload.code,
                    id: action.payload.id,
                }

                return state

            case WhistleblowerActionType.ADD_REPORTER_EMAIL:
                state.whistleblowerLoading = false
                state.whistleblowerError = null

                return state

            case WhistleblowerActionType.GET_REPORT:
                state.whistleblowerLoading = false
                state.whistleblowerError = null
                state.openReport = action.payload

                return state

            case WhistleblowerActionType.GET_MESSAGES:
                state.whistleblowerLoading = false
                state.whistleblowerError = null
                state.messages = action.payload

                return state

            case WhistleblowerActionType.SET_WHISTLEBLOWER_LOADING:
                state.whistleblowerLoading = true

                return state

            case WhistleblowerActionType.SET_WHISTLEBLOWER_ERROR:
                state.whistleblowerLoading = false
                state.whistleblowerError = action.payload.message

                return state

            case WhistleblowerActionType.CLEAR_WHISTLEBLOWER_ERROR:
                state.whistleblowerError = null

                return state

            case WhistleblowerActionType.RESET_WHISTLEBLOWER_ERROR_MESSAGE_STATE:
                state.whistleblowerLoading = false
                state.whistleblowerError = null

                return state

            case WhistleblowerActionType.WHISTLEBLOWER_LOGOUT:
                state.isWhistleblowerLoggedIn = false
                state.organizationalCode = null
                state.companyName = null

                return state

            case WhistleblowerActionType.LEAVE_REPORT:
                state.openReport = null
                state.messages = []
                state.whistleblowerLoading = false
                state.whistleblowerError = null

                return state

            case WhistleblowerActionType.SHOW_LATEST_MESSAGE:
                state.whistleblowerLoading = false
                state.whistleblowerError = null
                state.messages.push(action.payload)

                return state

            case WhistleblowerActionType.WHISTLEBLOWER_SHOW_LATEST_FILES:
                state.openReport.files.push(...action.payload)

                return state

            case WhistleblowerActionType.SHOW_REPORT_ERRORS:
                state.whistleblowerLoading = false
                state.reportErrors = action.payload

                return state

            case WhistleblowerActionType.REFRESH_CASE_AFTER_FORWARD:
                state.openReport.id = action.payload.incident.id
                state.openReport.forwarded_at =
                    action.payload.incident.forwarded_at

                return state

            case WhistleblowerActionType.CHANGE_REPORTING_CHANNEL_LANGUAGE:
                state.reportingChannelLanguage = action.payload

                return state

            default:
                return state
        }
    }
)

export default reducer
