import {
    Container,
    BackToHomeScreen,
    PolicyContainer,
    Heading,
    Paragraph,
    NavigationLink,
    NavigationLinkContainer,
    NavigationLinkDivider,
    Li,
} from '../styled'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import { useHistory } from 'react-router'
import { useTypedSelector } from '../../../../../hooks'
import styled from 'styled-components'

const Bold = styled.span`
    font-weight: bold;
`

const Underline = styled.span`
    text-decoration: underline;
`

const OrisHeading = styled(Heading)`
    font-weight: 700;
    font-size: 1.8rem;
`

const Ol = styled.ol`
    list-style-type: upper-roman;
`

const OrisPolicy = () => {
    const history = useHistory()

    const { companyName, organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    const { lineOfContacts } = useTypedSelector((state) => state.lineOfContact)

    return (
        <>
            <BackToHomeScreen>
                <NavigationArrowLink
                    onClickCallback={() => {
                        history.push(
                            `/organization/${organizationalCode}/whistleblower`
                        )
                    }}
                    linkText={'Back to Home Screen'}
                />
            </BackToHomeScreen>
            <Container>
                <NavigationLinkContainer>
                    <NavigationLink active={true}>{companyName}</NavigationLink>
                    <NavigationLinkDivider />
                    <NavigationLink active={false}>
                        Whistleblower Policy
                    </NavigationLink>
                </NavigationLinkContainer>
                <PolicyContainer>
                    <OrisHeading>
                        1. Indledning og deltagende virksomheder
                    </OrisHeading>
                    <Paragraph>
                        Denne whistleblowerpolitik har til formål at opstille
                        rammerne for ORIS Tandlægernes fælles
                        whistleblowerordning (”
                        <Bold>whistleblowerordningen</Bold>”), som udover de
                        deltagende virksomheders sædvanlige rapporteringskanaler
                        kan bruges til indberetning af alvorlige forhold,
                        herunder mistanke eller viden om ulovlig, uetisk eller
                        ureglementeret adfærd.
                    </Paragraph>
                    <Paragraph>
                        Du kan se en liste over de deltagende virksomheder på
                        indberetningssiden her
                        (https://app.whistleblower.walor.io/organization/n3OzK6VjUzkFkPaX3TEOr3DW/whistleblower)
                        (herefter enkeltvist ”<Bold>ORIS-enheder</Bold>” og
                        samlet ”<Bold>ORIS Tandlægerne</Bold>” eller ”
                        <Bold>Virksomheden</Bold>”).
                    </Paragraph>
                    <Paragraph>
                        Whistleblowerordningen administreres af ORIS Tandlægerne
                        A/S, CVR-nr.: 38325051 (”Hovedkontoret”), på vegne af
                        øvrige ORIS-enheder (som databehandler)
                    </Paragraph>
                    <Paragraph>
                        Det skal bemærkes, at whistleblowerordningen er
                        etableret på et frivilligt grundlag. Ordningen er derfor
                        ikke omfattet af whistleblowerloven med den konsekvens,
                        at indberetninger ikke må indeholde såkaldte ”følsomme
                        personoplysninger”, og at den for whistlebloweren
                        særlige beskyttelse, der er lovfæstet i
                        whistleblowerloven, ikke finder anvendelse. Som ansat
                        hos ORIS Tandlægerne garanteres dog en tilsvarende
                        beskyttelse som led i ansættelsen.
                    </Paragraph>
                    <OrisHeading>
                        2. Formålet med whistleblowerordning
                    </OrisHeading>
                    <Paragraph>
                        Formålet med whistleblowerordningen er at sikre åbenhed
                        og gennemsigtighed i forhold til eventuelle
                        (lov)overtrædelser og alvorlige uregelmæssigheder,
                        herunder:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>
                                at øge mulighederne for at ansatte kan ytre sig
                                om kritisable forhold på arbejdspladsen uden at
                                frygte for negative konsekvenser
                            </Li>
                            <Li>
                                at beskytte ansatte, som indgiver oplysninger
                                til whistleblowerordningen i god tro, og
                            </Li>
                            <Li>
                                at opdage og forebygge fejl og forsømmelser.
                            </Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Whistleblowerordningen skal ses som et supplement til
                        den direkte og daglige kommunikation om fejl og
                        utilfredsstillende forhold mv. Ordningen udgør et
                        supplement til eksisterende muligheder for f.eks. at
                        rette henvendelse til nærmeste leder
                        (klinikejer/klinikchef), ORIS’ øverste ledelse på
                        hovedkontoret eller evt. tillidsrepræsentant.
                    </Paragraph>
                    <Paragraph>
                        ORIS Tandlægerne opfordrer til at anvende
                        whistleblowerordningen, så eventuelle overtrædelser kan
                        imødegås hurtigt og effektivt internt. Whistlebloweren
                        kan dog frit vælge mellem at indgive indberetning til
                        arbejdspladsens whistleblowerordning eller den nationale
                        whistleblowerordning (Datatilsynet), der findes på
                        www.whistleblower.dk. Whistleblowerordningen udelukker
                        ikke muligheden for at indberette via eksterne kanaler
                        som f.eks. tilsynsmyndigheder.
                    </Paragraph>
                    <Heading>3. Hvem kan indgive oplysninger?</Heading>
                    <Paragraph>
                        Følgende persongrupper kan anvende
                        whistleblowerordningen (herefter benævnt ”
                        <Bold>whistleblower</Bold>”):
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>ORIS Tandlægernes ansatte</Li>
                            <Li>Klinikejere i ORIS Tandlægerne</Li>
                            <Li>Direktion og bestyrelsesmedlemmer</Li>
                            <Li>Aktionærer</Li>
                            <Li>
                                Lønnede eller ulønnede praktikanter i
                                Virksomheden
                            </Li>
                            <Li>Vikarer</Li>
                        </ul>
                    </Paragraph>
                    <OrisHeading>
                        4. Oplysninger, der kan indberettes om
                    </OrisHeading>
                    <Paragraph>
                        Whistleblowerordningen kan anvendes til indberetning i
                        god tro af oplysninger om alvorlige eller gentagne
                        lovovertrædelser samt øvrige alvorlige forhold, som ikke
                        nødvendigvis kan henføres til en bestemt
                        lovovertrædelse.
                    </Paragraph>
                    <Paragraph>
                        Der kan indberettes om enhver oplysning, herunder
                        rimelig mistanke, om faktiske eller potentielle
                        overtrædelser eller alvorlige forhold, og som har fundet
                        sted eller med stor sandsynlighed vil finde sted i ORIS
                        Tandlægerne og om forsøg på at skjule sådanne
                        overtrædelser.
                    </Paragraph>
                    <Paragraph>
                        En lovovertrædelse eller et øvrigt forhold vil som
                        udgangspunkt være alvorligt, hvis det er i
                        offentlighedens interesse at få det frem i lyset.
                        Overtrædelser af bagatelagtig karakter er ikke omfattet,
                        ligesom overtrædelser af accessoriske bestemmelser
                        heller ikke er omfattet. Indberetninger om
                        whistleblowerens eget ansættelsesforhold er som
                        udgangspunkt ikke omfattet, medmindre der er tale om
                        seksuel chikane eller grov chikane.
                    </Paragraph>
                    <Paragraph>
                        Oplysninger om øvrige forhold, herunder oplysninger om
                        overtrædelser af interne retningslinjer af mindre
                        alvorlig karakter, som bl.a. regler om sygefravær,
                        påklædning og oplysninger om andre personalerelaterede
                        konflikter på arbejdspladsen, vil som udgangspunkt ikke
                        anses som grove overtrædelser og skal dermed ikke
                        indberettes til whistleblowerordningen. Sådanne forhold
                        skal i stedet adresseres gennem de normale kanaler,
                        f.eks. til nærmeste leder, din tillidsrepræsentant (hvis
                        sådan findes på klinikken) eller den ansvarlige for
                        personaleforhold.
                    </Paragraph>
                    <Paragraph>
                        Indberetninger skal foretages i god tro. Det betyder, at
                        du skal have rimelig grund til at tro, at de
                        indberettede oplysninger er korrekte på tidspunktet for
                        indberetningen, og at oplysningerne er omfattet af
                        ordningen. Det betyder også, at ordningen ikke må bruges
                        til at foretage indberetning af bevidst forkerte
                        oplysninger.
                    </Paragraph>
                    <Paragraph>
                        I bilag 1 er der angivet eksempler på alvorlige
                        lovovertrædelser, alvorlige forhold og oplysninger, som
                        ikke er omfattet af whistleblowerordningen.
                    </Paragraph>
                    <Paragraph>
                        Vi gør særligt opmærksom på, at:
                        <ul>
                            <Li>
                                <Underline>
                                    Indberetninger må ikke indeholde
                                    patientoplysninger.
                                </Underline>{' '}
                                Patientoplysninger er omfattet af en lovbestemt
                                tavshedspligt i sundhedsloven, som ikke kan
                                fraviges. I stedet for at angive
                                personoplysninger om patienter, kan en situation
                                alternativt beskrives i generelle vendinger,
                                uden oplysninger så en patient kan identificeres
                                (dvs. ej heller journalnummer). Medarbejdere
                                opfordres til at kontakte den
                                virksomhedsansvarlige tandlæge på din klinik
                                eller ORIS’ tandlægefaglige konsulent, som har
                                mulighed for at kigge nærmere på eventuelle
                                konkrete sager.
                            </Li>
                            <Li>
                                <Underline>
                                    Indberetninger må ikke indeholde følsomme
                                    personoplysninger
                                </Underline>
                                , da ordningen ikke er omfattet af
                                whistleblowerloven. Følsomme personoplysninger
                                er oplysninger om race eller etnisk oprindelse,
                                politisk, religiøs eller filosofisk
                                overbevisning eller fagforeningsmæssigt
                                tilhørsforhold samt behandling af genetiske
                                data, helbredsoplysninger eller oplysninger om
                                en fysisk person seksuelle forhold eller
                                seksuelle orientering, jf.
                                databeskyttelsesforordningens artikel 9, stk. 1.{' '}
                            </Li>
                        </ul>
                    </Paragraph>
                    <OrisHeading>
                        5. Hvem håndterer og hvordan håndteres indberetninger?
                    </OrisHeading>
                    <Paragraph>
                        Det er kun muligt at foretage skriftlig indberetning
                        under ordningen via linket øverst i denne politik. Der
                        kan foretages anonyme indberetninger.
                    </Paragraph>
                    <Paragraph>
                        Alle indberetninger tages alvorligt, og ORIS Tandlægerne
                        har udpeget følgende koncernrepræsentanter, der screener
                        alle indberetninger for, om de er omfattet af ordningen
                        og om indberetningen bør undersøges nærmere:
                        <Ol>
                            {lineOfContacts.map((contact) => (
                                <Li>
                                    {contact.contact_information.position},{' '}
                                    {contact.first_name} {contact.last_name}{' '}
                                    {contact.contact_information.note}
                                </Li>
                            ))}
                        </Ol>
                    </Paragraph>
                    <Paragraph>
                        Såfremt indberetningen vedrører ovenstående personer,
                        kan du vælge ikke at klikke dem til, når du foretager
                        indberetningen. Såfremt koncernrepræsentanterne
                        vurderer, at indberetningen falder inden for rammerne af
                        whistleblowerordningen, foretager de en
                        habilitetsvurdering af, hvem i ledelsen i den
                        omhandlende ORIS-enhed (klinik) der kan behandle
                        indberetningen sammen med dem. Koncernrepræsentanterne
                        og ledelsesrepræsentanten vil tilsammen udgøre
                        whistleblowerenheden. I særlige tilfælde kan ovenstående
                        personer behandle indberetningen uden at inddrage
                        ledelsen i den omhandlende ORIS-enhed.
                    </Paragraph>
                    <Paragraph>
                        Whistleblowerenheden undersøger herefter indholdet af
                        indberetningen nærmere og træffer beslutning om
                        opfølgning på undersøgelsen, hvilket bl.a. kan indebære
                        – afhængigt af indberetningens indhold og karakter – at
                        indhente yderligere oplysninger internt i organisationen
                        eller yderligere dialog med whistlebloweren. Eksempler
                        på opfølgning:
                        <ul>
                            <Li>
                                Iværksættelse af en intern undersøgelse i
                                virksomheden.
                            </Li>
                            <Li>
                                Orientering af virksomhedens øverste ledelse
                                eller bestyrelse.
                            </Li>
                            <Li>
                                Anmeldelse til politiet eller relevant
                                tilsynsmyndighed.
                            </Li>
                            <Li>
                                Sagsafslutning grundet manglende eller
                                utilstrækkeligt bevismateriale.
                            </Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Hvis det vurderes, at indberetningen falder uden for
                        whistleblowerordningen, vil en whistleblower blive
                        orienteret herom. Samtidig vil whistlebloweren modtage
                        vejledning om, hvor der eventuelt kan rettes henvendelse
                        i stedet. I visse tilfælde kan sådanne indberetninger
                        blive videresendt internt i ORIS Tandlægerne, hvis det
                        vurderes, at det er sagligt og nødvendigt at følge op på
                        det indberettede forhold, selvom det falder uden for
                        whistleblowerordningen. Dette kræver dog, at der findes
                        hjemmel til internt at videregive oplysningerne.
                    </Paragraph>
                    <OrisHeading>6. Beskyttelse af whistleblowere</OrisHeading>
                    <Paragraph>
                        En whistleblower, som i god tro indberetter en rimelig
                        mistanke via whistleblowerordningen, vil ikke blive mødt
                        med repressalier eller blive udsat for negative
                        konsekvenser, herunder konsekvenser af ansættelsesretlig
                        karakter, selvom indberetningen senere måtte vise sig at
                        være ubegrundet.
                    </Paragraph>
                    <Paragraph>
                        Ved ”repressalier” forstås en ufordelagtig behandling
                        eller ufordelagtig følge som reaktion på en
                        indberetning. Det kan f.eks. være suspension,
                        afskedigelse, degradering mv
                    </Paragraph>
                    <Paragraph>
                        Vi gør dog særligt opmærksom på, at whistlebloweren, der
                        indberetter under whistleblowerordningen, ikke omfattes
                        af whistleblowerloven og den heri lovfæstede
                        beskyttelse, da der er tale om en frivillig ordning.
                        Medarbejdere der bidrager til ordningen ved at foretage
                        undersøgelser eller afgive oplysninger, vil dog så vidt
                        muligt blive sikret en tilsvarende beskyttelse mod
                        repressalier
                    </Paragraph>
                    <Paragraph>
                        Omvendt vil en whistleblower, der foretager en
                        indberetning i ond tro og velvidende, at de indberettede
                        oplysninger er urigtige eller vildledende, ikke være
                        beskyttet mod repressalier m.v. Indberetninger indgivet
                        via whistleblowerordningen i ond tro, kan medføre
                        politianmeldelse, negative ansættelsesretlige reaktioner
                        eller medføre kontraktretlige konsekvenser for den
                        person eller virksomhed, der har indgivet oplysningen.
                    </Paragraph>
                    <OrisHeading>7. Anonymitet og fortrolighed</OrisHeading>
                    <Paragraph>
                        Information om whistlebloweren vil blive holdt
                        fortroligt i videst mulig udstrækning. Whistlebloweren
                        kan frit vælge, om indberetningen skal være anonym,
                        eller om whistlebloweren vil angive sine personlige
                        kontaktoplysninger.
                    </Paragraph>
                    <Paragraph>
                        Hvis en indberetning er foretaget fra en computer, der
                        er på ORIS Tandlægernes netværk, er der en (teoretisk)
                        risiko for, at de besøgte hjemmesider bliver logget i
                        klinikkens log. Denne (teoretiske) risiko kan elimineres
                        ved at foretage indberetningen fra en computer, der ikke
                        er på ORIS Tandlægernes netværk.
                    </Paragraph>
                    <Paragraph>
                        En whistleblower, der ønsker at være anonym, skal sørge
                        for, at whistleblowerens identitet ikke fremgår direkte
                        eller direkte af indberetningen. Vær i denne forbindelse
                        opmærksom på, at selvom identiteten ikke oplyses, kan
                        indberetningen i sig selv, eller de undersøgelser der
                        foretages for at følge op på indberetningen, eventuelt
                        afsløre whistleblowerens identitet. Anonymitet kan
                        derfor ikke altid garanteres.
                    </Paragraph>
                    <Paragraph>
                        Hvis whistlebloweren vælger at tilkendegive sin
                        identitet i forbindelse med en indberetning, der er
                        omfattet af whistleblowerordningen, vil den relevante
                        whistleblowerenhed holde whistleblowerens identitet
                        fortrolig i videst muligt omfang. Det betyder, at
                        whistleblowerens identitet som udgangspunkt ikke vil
                        blive videregivet til personer uden for den relevante
                        whistleblowerenhed. Det bemærkes dog i den forbindelse,
                        at den registrerede efter persondataforordningens
                        artikel 15 som udgangspunkt har ret til indsigt,
                        medmindre der i medfør af databeskyttelseslovens § 22
                        kan undtages fra den registreredes anmodning herom,
                        herunder fx hensynet til ORIS Tandlægernes interesse i
                        effektivt at behandle indberetninger, herunder at
                        afdække og opklare strafbare forhold.
                    </Paragraph>
                    <Paragraph>
                        Oplysninger fra en indberetning kan efter
                        omstændighederne videregives, f.eks. med det formål at
                        følge op på indberetningerne, til brug for en eventuel
                        retssag vedrørende det indberettede forhold, eller hvis
                        det er påkrævet ved lov.
                    </Paragraph>
                    <Paragraph>
                        Whistlebloweren bliver underrettet forud for
                        videregivelse af oplysninger om whistleblowerens
                        identitet, medmindre det vil bringe en relateret
                        undersøgelse eller retssag i fare. Det vil f.eks. kunne
                        være tilfældet, hvis en sådan underretning konkret
                        vurderes at ville medføre risiko for, at beviser vil
                        blive skjult eller ødelagt, at vidner vil blive
                        påvirket, eller hvis der er mistanke om, at
                        whistlebloweren bevidst har indgivet falsk indberetning.
                    </Paragraph>
                    <OrisHeading>
                        8. Orientering til whistlebloweren
                    </OrisHeading>
                    <Paragraph>
                        Whistlebloweren bliver efter indberetning videresendt
                        til en kvitteringsside, som indeholder en
                        whistleblowerkode til den sikrede postkasse, hvor
                        whistlebloweren kan logge ind og følge indberetningen.
                    </Paragraph>
                    <Paragraph>
                        Whistlebloweren modtager:
                        <Ol>
                            <Li>
                                en bekræftelse på modtagelsen af indberetningen
                                inden for syv dage efter modtagelsen heraf, og
                            </Li>
                            <Li>
                                feedback hurtigst muligt og som udgangspunkt
                                inden for tre måneder fra bekræftelsen af
                                modtagelsen af indberetningen.
                            </Li>
                        </Ol>
                    </Paragraph>
                    <Paragraph>
                        Ved feedback forstås en meddelelse om hvilke tiltag der
                        er iværksat eller påtænkes iværksat, og hvorfor denne
                        opfølgning er valgt. Den af whistleblowerenheden
                        meddelte feedback skal til enhver tid iagttage de
                        persondataretlige regler, hvilket kan medføre
                        begrænsninger i forhold til den feedback, som
                        whistlebloweren modtager.
                    </Paragraph>
                    <Paragraph>
                        Hvis det ikke er muligt at give feedback inden for
                        fristen på grund f.eks. på grund af indberetningens art
                        og kompleksitet, vil whistleblowerenheden underrette
                        whistlebloweren herom, og om eventuel yderligere
                        feedback kan forventes. Det kan f.eks. skyldes, at der
                        er iværksat en intern undersøgelse, der ikke kan
                        afsluttes inden for fristen.
                    </Paragraph>
                    <OrisHeading>
                        9. Orientering til den indberettede
                    </OrisHeading>
                    <Paragraph>
                        Når en foreløbig undersøgelse har fundet sted, og alle
                        relevante beviser er sikret, underrettes den
                        indberettede person om bl.a. identiteten på medlemmer af
                        whistleblowerenheden, som er ansvarlig for undersøgelsen
                        af indberetningen og om de forhold, indberetningen
                        vedrører
                    </Paragraph>
                    <Paragraph>
                        ORIS Tandlægerne iagttager i øvrigt den berørte persons
                        rettigheder i henhold til persondataforordningen.{' '}
                    </Paragraph>
                    <Paragraph>
                        Identiteten på den fysiske eller juridiske person, der
                        indberettes om, vil blive beskyttet under behandlingen
                        af indberetningen og vil alene blive videregivet til
                        personer uden for whistleblowerenheden, hvis det sker
                        som led i opfølgning på en indberetning, for at imødegå
                        alvorlige lovovertrædelser eller øvrige alvorlige
                        forhold, eller det i øvrigt er påkrævet efter loven.{' '}
                    </Paragraph>
                    <OrisHeading>10. Datasikkerhed</OrisHeading>
                    <Paragraph>
                        Indberetningsløsningen er leveret af Walor, der er en
                        uafhængig part, som garanterer for sikkerheden. Alle
                        indberetninger vil blive opbevaret forsvarligt, og det
                        vil kun være muligt for relevante personer i
                        whistleblowerenheden (sagsbehandlerne) at tilgå
                        oplysningerne.
                    </Paragraph>
                    <Paragraph>
                        Systemet logger ikke IP-adresser og maskin-ID'er og
                        krypterer alle behandlede data.
                    </Paragraph>
                    <OrisHeading>
                        11. Behandling af personoplysninger
                    </OrisHeading>
                    <Paragraph>
                        Når du foretager en indberetning til ORIS Tandlægernes
                        whistleblowerordning behandler vi dine
                        personoplysninger, medmindre du vælger at være anonym.
                        Det kan også ske, at dine personoplysninger indgives til
                        whistleblowerordningen af andre.
                    </Paragraph>
                    <Paragraph>
                        Formålet med at behandle personoplysninger om dig er at
                        håndtere og undersøge indberetninger i vores
                        whistleblowerordning, herunder at undersøge eventuelle
                        ulovligheder eller alvorlige uregelmæssigheder hos ORIS
                        Tandlægerne med henblik på at sikre forbedring,
                        forebyggelse og afværgelse af fejl og ulovligheder.
                    </Paragraph>
                    <Paragraph>
                        Vi behandler dine oplysninger på baggrund af vores
                        legitime interesse jf. databeskyttelsesforordningen art.
                        6, stk. 1 litra f, som er at kunne undersøge
                        indberetninger via whistleblowerordningen, herunder
                        eventuelle alvorlige (lov)overtrædelse. Tilsvarende
                        gælder (eventuelle) oplysninger om strafbare forhold,
                        jf. databeskyttelseslovens § 8
                    </Paragraph>
                    <Paragraph>
                        Du kan læse mere i vores privatlivspolitik for
                        whistleblowerordningen, som kan findes nederst i
                        dokumentet (bilag 2)
                    </Paragraph>
                    <Paragraph>
                        Ved en anonym indberetning, vil der som udgangspunkt
                        ikke blive behandlet personoplysninger om
                        whistlebloweren.
                    </Paragraph>
                    <OrisHeading>12. Spørgsmål</OrisHeading>
                    <Paragraph>
                        Spørgsmål til de enkelte indberetninger eller ORIS
                        Tandlægernes whistleblowerordning i det hele taget kan
                        rettes til Katrine Benée, jurist og EA, kgb@oris.dk,
                        tlf. 2971 9929.
                    </Paragraph>
                    <Paragraph>
                        Du kan også se videovejledninger her:
                        https://app.whistleblower.walor.io/organization/n3OzK6VjUzkFkPaX3TEOr3DW/how-it-works
                    </Paragraph>
                    <OrisHeading>
                        Bilag 1. Eksempler på hvad der er omfattet/ikke er
                        omfattet af whistleblowerordningen
                    </OrisHeading>
                    <Heading>Eksempler på alvorlige lovovertrædelser</Heading>
                    <Paragraph>
                        Overtrædelser af straffeloven, der i almindelighed vil
                        være omfattet af anvendelsesområdet:
                        <ul>
                            <Li>Bestikkelse, jf. straffelovens § 144.</Li>
                            <Li>Dokumentfalsk, jf. straffelovens § 171.</Li>
                            <Li>
                                Hacking, aflytning, optagelse af samtaler mellem
                                andre m.v., jf. straffelovens § 263.{' '}
                            </Li>
                            <Li>Tyveri, jf. straffelovens § 276.</Li>
                            <Li>Underslæb, jf. straffelovens § 278. </Li>
                            <Li>Bedrageri, jf. straffelovens § 279.</Li>
                            <Li>Databedrageri, jf. straffelovens § 279a.</Li>
                            <Li>Mandatsvig, jf. straffelovens § 280.</Li>
                            <Li>Afpresning, jf. straffelovens § 281.</Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Overtrædelser af særlovgivning eller anden lovgivning,
                        der i almindelighed vil være omfattet af
                        anvendelsesområdet
                        <ul>
                            <Li>Overtrædelser af skattelovgivningen.</Li>
                            <Li>Tilsidesætte af tavshedspligter.</Li>
                            <Li>Overtrædelser af lov om bogføring.</Li>
                            <Li>
                                Overtrædelser af godskørselsloven, jf.
                                godskørselslovens §§ 17-17 b.
                            </Li>
                            <Li>
                                Overtrædelser af luftfartsloven, jf.
                                luftfartslovens § 149.
                            </Li>
                            <Li>
                                Tilsidesættelse af en lovbestemt handlepligt.
                            </Li>
                            <Li>Overtrædelse af regler om magtanvendelse.</Li>
                            <Li>
                                Grove eller gentagne overtrædelser af
                                forvaltningsretlige principper, herunder
                                undersøgelsesprincippet, krav om saglighed,
                                magtfordrejningsgrundsætningen og
                                proportionalitet (jf. dog nærmere nedenfor om
                                bagatelagtige overtrædelser).
                            </Li>
                            <Li>
                                Bevidst vildledning af patienter og
                                samarbejdspartnere. Chikane
                            </Li>
                            <Li>
                                Seksuel chikane, jf. ligebehandlingslovens § 1,
                                stk. 4, jf. stk. 6.
                            </Li>
                            <Li>
                                Grov chikane, f.eks. på grund af race, køn,
                                farve, national eller social oprindelse,
                                politisk eller religiøst tilhørsforhold
                            </Li>
                        </ul>
                    </Paragraph>
                    <Heading>Eksempler på alvorlige forhold</Heading>
                    <Paragraph>
                        Forhold der i almindelighed anses som alvorlige:
                        <ul>
                            <Li>
                                Tilsidesættelse af faglige standarder, der
                                f.eks. vil kunne medføre en risiko for personers
                                sikkerhed og sundhed.
                            </Li>
                            <Li>
                                Grove eller gentagne overtrædelser af
                                arbejdspladsens interne retningslinjer om f.eks.
                                tjenesterejser, gaver eller regnskabsaflæggelse
                                (jf. dog nærmere nedenfor om bagatelagtige
                                overtrædelser).
                            </Li>
                            <Li>
                                Alvorlige fejl og alvorlige uregelmæssigheder
                                forbundet med it-drift eller it-systemstyring.
                            </Li>
                            <Li>
                                Særlige tilfælde, hvor mindre
                                samarbejdsvanskeligheder indebærer store risici,
                                og dermed udgør et alvorligt forhold.
                            </Li>
                        </ul>
                    </Paragraph>
                    <Heading>
                        Eksempler på oplysninger, som ikke er omfattet af lovens
                        anvendelsesområde
                    </Heading>
                    <Paragraph>
                        <ul>
                            <Li>
                                Oplysninger af bagatelagtig karakter,
                                oplysninger om øvrige forhold, herunder
                                oplysninger om overtrædelse af interne
                                retningslinjer om sygefravær, rygning,
                                påklædning eller accessoriske bestemmelser som
                                f.eks. manglende overholdelse af
                                dokumentationspligt.
                            </Li>
                            <Li>
                                Oplysninger om whistleblowerens eget
                                ansættelsesforhold, herunder konflikter mellem
                                medarbejdere, samarbejdsvanskeligheder eller
                                forhold, der henhører under det fagretlige
                                system, medmindre der er tale om seksuel chikane
                                eller anden form for grov chikane.
                            </Li>
                        </ul>
                    </Paragraph>
                    <OrisHeading>
                        Bilag 2. Privatlivspolitik for ORIS Tandlægernes
                        whistleblowerordning
                    </OrisHeading>
                    <Paragraph>
                        Denne privatlivspolitik forklarer, hvordan ORIS
                        Tandlægerne A/S og alle associerede selskaber
                        (klinikker) (”ORIS Tandlægerne”, ”vi”, eller ”os”)
                        behandler personoplysninger i forbindelse med
                        indberetninger til ORIS Tandlægernes
                        whistleblowerordning.
                    </Paragraph>
                    <Paragraph>
                        Nedenfor beskrives nærmere hvilken behandling af
                        personoplysninger, der finder sted, og hvilke
                        rettigheder du har, hvis der rapporteres om dig via
                        whistleblowerordningen, samt dine rettigheder, hvis du
                        benytter whistleblowerordningen til at foretage en
                        indberetning om en anden person.
                    </Paragraph>
                    <Paragraph>
                        Denne politik vedrører kun håndtering og undersøgelse af
                        indberetninger under whistleblowerordningen. Politikken
                        skal derfor – i forhold til ansatte – ses i sammenhæng
                        med ORIS Tandlægernes øvrige relevante politikker og
                        procedurer.
                    </Paragraph>
                    <Heading>1. Dataansvarlige</Heading>
                    <Paragraph>
                        Den juridiske enhed, som er ansvarlig for behandling af
                        dine personoplysninger, er den ORIS-enhed, hvor du er
                        ansat.
                    </Paragraph>
                    <Paragraph>
                        Du kan se en oversigt over enheder og kontaktoplysninger
                        her
                        <ul>
                            <Li>
                                ORIS Tandlægerne Sæby, CVR 38368621, kontakt:
                                saeby@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Hammel, CVR 38369091, kontakt:
                                hammel@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Viborg, CVR 3836 8796, kontakt:
                                viborg@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Kolding, CVR 38371835, kontakt:
                                kolding@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Assens, CVR 38371789, kontakt:
                                Assens
                            </Li>
                            <Li>
                                ORIS Tandlægerne Slotsgade, CVR 38371908,
                                kontakt: slotsgade21@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Københavns Implantat Center,
                                CVR 3836 7889, kontakt: kic@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Svendborg, CVR 39291576,
                                kontakt: detgulepakhus@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Kalundborg, CVR 39722364,
                                kontakt: kalundborg@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Lyngby, CVR 39774747, kontakt:
                                lyngby@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Nakskov, CVR 40055770, kontakt:
                                nakskov@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Nytorv, CVR 42686395, kontakt:
                                nytorv@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Frejlev, CVR 42798673, kontakt:
                                frejlev@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Thisted, CVR 42798231, kontakt:
                                thisted@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne Ved Broen, CVR 43640399,
                                kontakt: vedbroen@oris.dk
                            </Li>
                            <Li>
                                ORIS Dentallaboratorium ApS, CVR 39432676,
                                lz@oris.dk
                            </Li>
                            <Li>
                                ORIS Tandlægerne A/S, CVR 38325051, kontakt:
                                gdpr@oris.dk
                            </Li>
                        </ul>
                    </Paragraph>
                    <Heading>2. Formål og retsgrundlag </Heading>
                    <Heading>Whistleblower</Heading>
                    <Paragraph>
                        I forbindelse med, at du laver en indberetning via vores
                        whistleblowerordning, behandler vi personoplysninger om
                        dig, forudsat at din indberetning ikke er anonym.
                    </Paragraph>
                    <Paragraph>
                        Personoplysningerne som fremkommer, er dit navn, e-mail
                        og telefonnummer samt øvrige oplysninger som indgår i
                        indberetningen. Oplysninger om lovovertrædelser eller
                        mulige lovovertrædelser kan indgå i behandlingen, hvis
                        der er mistanke om, at der er foretaget en bevidst falsk
                        indberetning. Vi gør særligt opmærksom på, at vi ikke må
                        behandle følsomme personoplysninger, da
                        whistleblowerordningen ikke er omfattet af
                        whistleblowerloven. VI ønsker derfor ikke at modtage
                        følsomme personoplysninger i indberetninger til
                        whistleblowerordningen.
                    </Paragraph>
                    <Paragraph>
                        Vi modtager personoplysninger fra din indberetning, og
                        vi kan indsamle yderligere oplysninger om dig, når vi
                        følger op på indberetningen, fx ved at indsamle
                        skriftligt materiale, eller når vi afholder samtaler.
                    </Paragraph>
                    <Paragraph>
                        Formålet med at behandle personoplysninger om dig er at
                        håndtere og undersøge indberetninger i vores
                        whistleblowerordning, herunder at undersøge eventuelle
                        ulovligheder eller alvorlige uregelmæssigheder hos ORIS
                        Tandlægerne med henblik på at sikre forbedring,
                        forebyggelse og afværgelse af fejl og ulovligheder.
                    </Paragraph>
                    <Paragraph>
                        Vi behandler dine oplysninger på baggrund af vores
                        legitime interesse, jf. databeskyttelsesforordningen
                        art. 6, stk. 1 litra f, som er at kunne undersøge
                        indberetninger via whistleblowerordningen. Tilsvarende
                        gælder (eventuelle) oplysninger om strafbare forhold,
                        jf. databeskyttelseslovens § 8.
                    </Paragraph>
                    <Heading>Den der indberettes om</Heading>
                    <Paragraph>
                        I forbindelse med, at der sker en indberetning via vores
                        whistleblowerordning, kan det ske at vi behandler
                        personoplysninger om dig, hvis det er dig der
                        indberettes om. Der vil som udgangspunkt være tale om
                        dine almindelige personoplysninger. Følsomme
                        oplysninger, herunder oplysninger om seksuelle
                        oplysninger, kan efter omstændighederne også indgå i
                        behandlingen. Der kan også være tale om oplysninger om
                        lovovertrædelser eller mulige lovovertrædelse.
                    </Paragraph>
                    <Paragraph>
                        Oplysningerne indsamles fra den whistleblower der
                        anvender ordningen, dvs. medarbejdere, aktionærer,
                        ledelsesmedlemmer og klinikejere hos ORIS Tandlægerne,
                        samt eventuelle øvrige relevante parter, såfremt der
                        iværksættes en undersøgelse baseret på en indberetning
                    </Paragraph>
                    <Paragraph>
                        Formålet med at behandle personoplysninger om dig er at
                        håndtere og undersøge indberetninger i vores
                        whistleblowerordning, herunder at undersøge eventuelle
                        ulovligheder eller alvorlige uregelmæssigheder hos ORIS
                        Tandlægerne med henblik på at sikre forbedring,
                        forebyggelse og afværgelse af fejl og ulovligheder.{' '}
                    </Paragraph>
                    <Paragraph>
                        Vi behandler dine oplysninger på baggrund af vores
                        legitime interesse jf. databeskyttelsesforordningen art.
                        6, stk. 1 litra f, som er at kunne undersøge
                        indberetninger via whistleblowerordningen, herunder
                        eventuelle alvorlige (lov)overtrædelse. Tilsvarende
                        gælder (eventuelle) oplysninger om strafbare forhold,
                        jf. databeskyttelseslovens § 8.{' '}
                    </Paragraph>
                    <Paragraph>
                        Hvis der bliver indrapporteret om dig via
                        whistleblowerordningen, vil du blive underrettet
                        hurtigst muligt efter at en foreløbig undersøgelse har
                        fundet sted, og alle relevante beviser er sikret, og du
                        vil i den forbindelse få information om identiteten på
                        den/de personer, som er ansvarlige for undersøgelsen af
                        indberetningen og de forhold, indberetningen vedrører.
                    </Paragraph>
                    <Paragraph>
                        Som anført nedenfor i afsnittet om dine generelle
                        rettigheder, har du ret til at få indsigt i anmeldelsen,
                        der er indgivet om dig. Du har også ret til at anmode om
                        at få berigtiget information i anmeldelsen, som du mener
                        er urigtig, vildledende eller ufuldstændig. Hvis din
                        anmodning om berigtigelse ikke kan imødekommes, vil
                        oplysningerne blive suppleret med dine bemærkninger.
                    </Paragraph>
                    <Heading>3. Konsekvenser ved behandlingen</Heading>
                    <Paragraph>
                        Indberetning til og undersøgelse af indberetninger til
                        whistleblowerordningen kan have betydelige konsekvenser
                        for den indberettede, idet indberetninger vedrører
                        overtrædelser eller mistanke om overtrædelser af
                        lovgivningen, således som ordningen i øvrigt er
                        afgrænset i de særskilte retningslinjer for ordningen.
                    </Paragraph>
                    <Paragraph>
                        Tilsvarende kan indberetning til whistleblowerordningen
                        have betydelige konsekvenser for den person, der har
                        foretaget indberetningen, hvis der er tale om en bevidst
                        falsk indberetning. Sådanne tilfælde kan bl.a. få
                        strafferetlige konsekvenser.
                    </Paragraph>
                    <Heading>4. Overladelse og videregivelse</Heading>
                    <Paragraph>
                        Vi benytter en række tredjeparter til opbevaring og
                        behandling af oplysningerne, herunder IT-leverandører.
                        Disse behandler udelukkende oplysninger på vores vegne
                        og må ikke anvende dem til deres egne formål.{' '}
                    </Paragraph>
                    <Paragraph>
                        Dine oplysninger vil desuden blive delt internt med
                        relevant ledende personale til at undersøge
                        indberetningen, herunder vores hovedkontor, men kan også
                        deles med politiet, domstole, advokat, revisor samt
                        andre rådgivere og offentlige myndigheder.
                    </Paragraph>
                    <Paragraph>
                        Oplysninger fra en indberetning kan efter
                        omstændighederne videregives, f.eks. med det formål at
                        følge op på indberetningerne, til brug for en eventuel
                        retssag vedrørende det indberettede forhold eller hvis
                        det er påkrævet ved lov. Whistlebloweren bliver
                        underrettet forud for videregivelse af oplysninger om
                        whistleblowerens identitet, medmindre det vil bringe en
                        relateret undersøgelse eller retssag i fare. Det vil
                        f.eks. kunne være tilfældet, hvis en sådan underretning
                        konkret vurderes at ville medføre risiko for, at beviser
                        vil blive skjult eller ødelagt, at vidner vil blive
                        påvirket, eller hvis der er mistanke om, at
                        whistlebloweren bevidst har indgivet falsk indberetning.
                    </Paragraph>
                    <Paragraph>
                        Vi kan endvidere videregive personoplysninger, hvis vi
                        udtrykkeligt modtager samtykke hertil.
                    </Paragraph>
                    <Heading>Overførsler til lande udenfor EU/EØS</Heading>
                    <Paragraph>
                        Dine personoplysninger overføres som udgangspunkt ikke
                        til lande udenfor EU/EØS.
                    </Paragraph>
                    <Heading>5. Opbevaringsperiode</Heading>
                    <Paragraph>
                        Indberetninger gemmes som udgangspunkt op til 60 dage
                        efter, at behandlingen af indberetningen er afsluttet,
                        medmindre ORIS Tandlægerne har en legitim grund til
                        fortsat opbevaring, f.eks. hvis der foretages anmeldelse
                        til politiet eller anden myndighed, hvis det er påkrævet
                        efter anden lovgivning, eller hvis der er grund til at
                        tro, at indberetningen kan bestyrkes af senere indkomne
                        indberetninger om samme forhold.
                    </Paragraph>
                    <Paragraph>
                        Hvis der på baggrund af de indsamlede oplysninger
                        gennemføres en disciplinær sanktion over for den
                        indberettede person, eller der i øvrigt foreligger
                        grunde til, at det er sagligt og nødvendigt fortsat at
                        opbevare oplysninger om den indberettede person,
                        opbevares oplysningerne, hvor der er tale om en ansat, i
                        den ansattes personalemappe.
                    </Paragraph>
                    <Heading>6. Dine rettigheder</Heading>
                    <Paragraph>
                        Du kan ved henvendelse til Virksomheden:
                        <ul>
                            <Li>opnå indsigt i din personoplysninger </Li>
                            <Li>
                                få berigtiget dine registrerede
                                personoplysninger
                            </Li>
                            <Li>få slettet personoplysninger om dig selv</Li>
                            <Li>
                                få udleveret dine personoplysninger
                                (dataportabilitet) mhp. at overflytte til en
                                anden dataansvarlig
                            </Li>
                            <Li>gøre indsigelse mod behandlingen</Li>
                            <Li>
                                få begrænset behandlingen af dine
                                personoplysninger.
                            </Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Når du henvender dig med en anmodning om at gøre brug af
                        ovenstående rettigheder, vil vi svare dig indenfor en
                        måned. Hvis vi ikke kan imødekomme din anmodning, får du
                        en begrundelse for det.
                    </Paragraph>
                    <Paragraph>
                        For at gøre brug af dine rettigheder, eller hvis du har
                        spørgsmål til ovenstående, kan du henvende dig til den
                        relevante ORIS-enhed via kontaktoplysningerne øverst
                        eller til hovedkontoret enten på adressen: Skæringvej
                        94, 8520 Lystrup (ORIS Tandlægerne A/S. CVR nr.:
                        38325051) eller på mail: GDPR@oris.dk
                    </Paragraph>
                    <Paragraph>
                        Hvis du er utilfreds med måden, hvorpå vi behandler dine
                        oplysninger, har du ret til at indgive en klage til
                        Datatilsynet. Du finder Datatilsynets kontaktoplysninger
                        på www.datatilsynet.dk.
                    </Paragraph>
                </PolicyContainer>
            </Container>
        </>
    )
}

export default OrisPolicy
