import styled from 'styled-components'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaFileCsv } from 'react-icons/fa'
import { AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { NextButton } from '../../../../reusable/buttons'
import { ErrorMessage, FormInput50 } from '../../../../styled/formElements'
import {
    FileName,
    FilePreview,
    NewCasePageFrame,
    NewCasePageFrameHeading,
    NewCasePageFrameTagline,
    TaglineLight,
} from '../styled'
import InformationYouCouldInclude from '../components/InformationYouCouldInclude'
import { LocalFile, QuestionInterface, VoiceRecording } from '../NewCasePage'
import { ReportQuestionInterface } from '../../../../../ts/interfaces/ReportQuestionInterface'
import { useEffect, useState } from 'react'
import { checkAnswer } from '../../../../../helpers/validation/answer'
import { FileIcon, FileProgressBox } from '../styled/Files'
import Vapor from 'laravel-vapor'
import config from '../../../../../config'
import RecordVoiceMessageModal, {
    formatTime,
} from '../../../auth/login/RecordVoiceMessageModal'
import { FiMic, FiTrash2, FiPlusCircle } from 'react-icons/fi'

const { backendUrl, env } = config

export const AllFieldsTagline = styled.p`
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => `${theme.black}50`};
    font-size: 1.6rem;
`

const ReportForm = styled.form`
    @media screen and (max-width: 600px) {
        margin-block-end: 3rem;
    }
`

const FormField = styled.div`
    width: 50rem;
    text-align: left;
    margin: 1rem 0;
    position: relative;

    @media (max-width: 600px) {
        width: 90%;
        margin: 1rem auto;
    }
`

const FormLabel = styled.label`
    font-size: 1.4rem;
`

const FormTextarea = styled.textarea`
    height: 15rem;
    width: 100%;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
`

const FileInputLabel = styled.div`
    min-height: 9.6rem;
    max-height: 20rem;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    border: 2px dotted ${({ theme }) => theme.lineGrey};
    cursor: pointer;
    margin-top: 0.5rem;
    overflow-y: auto;
`

const FIleInputLabelWhite = styled(FileInputLabel)`
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    border: 2px dotted ${({ theme }) => theme.lineGrey};
    background: ${({ theme }) => theme.white};
    cursor: default;
`

const AddFileInputLabel = styled.label`
    width: 92%;
    height: 2.5rem;
    padding: 0 1rem;
    margin-inline: 2rem;
    margin-block-end: 1rem;
    border-radius: 1.5rem;
    border: none;
    background-color: #247dd0;
    opacity: 1;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    @media screen and (max-width: 600px) {
        width: 88%;
    }
`

const FileInputLabelHeading = styled.h3`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    padding-top: 3rem;
`
const FileInput = styled.input`
    display: none;
`

const FileInputLabelTagline = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.darkColor};
    margin-top: 1.4rem;
    opacity: 0.3;
`

const VoiceButton = styled.div`
    position: absolute;
    left: 2rem;
    bottom: -0.5rem;
    border-radius: 2rem;
    display: flex;
    gap: 1rem;
    border: none;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.lightGrey};
    font-size: 1.4rem;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    z-index: 2;
    height: 3.4rem;
    width: 20rem;
    padding: 0 1rem;
    width: fit-content;
`

const RecordingPreview = styled(VoiceButton)`
    cursor: none;
    width: 25rem;
`

const MicIcon = styled(FiMic)`
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    font-size: 1.4rem;
`

const DeleteRecordingButton = styled.div`
    height: 3.4rem;
    width: 12rem;
    border-radius: 2rem;
    position: absolute;
    left: 19rem;
    bottom: -0.5rem;
    z-index: 1;

    background-color: lightcoral;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
`

const DeleteIcon = styled(FiTrash2)`
    color: ${({ theme }) => theme.red};
    margin-right: 1rem;
    font-size: 2.2rem;
`

interface FillInReportProps {
    updateReport: (key: string, value: any) => void
    updatePage: () => void
    removeSingleFile: (awsKey: string) => void
    title: null | string
    description: null | string
    questions: QuestionInterface[]
    setQuestions: React.Dispatch<React.SetStateAction<QuestionInterface[]>>
    voiceRecording: null | VoiceRecording
}

const FillInReport: React.FC<FillInReportProps> = ({
    updateReport,
    updatePage,
    title,
    description,
    removeSingleFile,
    questions,
    setQuestions,
    voiceRecording,
}) => {
    const translation = useTranslation()
    const { reportQuestions } = useTypedSelector(
        (state) => state.reportQuestions
    )
    const [answer, setAnswer] = useState('')
    const [currentAnswer, setCurrentAnswer] = useState<number>()
    const [answerError, setAnswerError] = useState('')

    const handleAnswerValidation = () => {
        // eslint-disable-next-line
        questions.map((question: QuestionInterface) => {
            if (question.organization_custom_question_id === currentAnswer) {
                const validationError = checkAnswer(answer)
                setAnswerError(validationError)
            }
        })
    }

    const deleteRecording = () => {
        updateReport('voiceRecording', null)
    }

    const handleAnswerChange = (
        inputValue: string,
        questionItem: ReportQuestionInterface
    ) => {
        const newQuestions = questions.map((question: QuestionInterface) => {
            if (question.organization_custom_question_id === questionItem.id) {
                return {
                    ...question,
                    answer: inputValue,
                }
            }
            return question
        })
        setQuestions(newQuestions)
        updateReport('questions', newQuestions)
    }

    const [isUploading, setIsUploading] = useState(false)
    const [localFiles, setLocalFiles] = useState<LocalFile[] | null>(null)

    const handleUploadFiles = (newFiles: FileList | null) => {
        if (!newFiles) return

        const formattedFiles = Array.from(newFiles).map((file) => ({
            file,
            key: Math.random().toString(36).substr(2, 9),
            progress: 0,
            awsKey: null,
            extension: null,
        }))

        setIsUploading(true)

        if (localFiles) {
            setLocalFiles([...localFiles, ...formattedFiles])
        } else {
            setLocalFiles(formattedFiles)
        }
    }

    useEffect(() => {
        if (!isUploading || !localFiles) return

        setIsUploading(false)

        const handleStreamFile = async (file: LocalFile) => {
            try {
                const fileInState = localFiles?.find((f) => f.key === file.key)

                if (!fileInState) return

                const bucket =
                    env === 'local' ? 'walor-staging' : 'walor-prod-api'

                const fileObj = file.file

                const cleanedBlob = new Blob([fileObj.slice(0, fileObj.size)], {
                    type: fileObj.type,
                })

                // Create a new File object from the cleaned Blob. Remove some metadata this way
                const cleanedFile = new File([cleanedBlob], fileObj.name, {
                    type: fileObj.type,
                })

                const response = await Vapor.store(cleanedFile, {
                    progress: (progress) => {
                        setLocalFiles((prevLocalFiles) => {
                            if (!prevLocalFiles) return prevLocalFiles

                            return prevLocalFiles.map((f) => {
                                if (f.key === file.key) {
                                    return {
                                        ...f,
                                        progress: Math.round(progress * 100),
                                    }
                                }
                                return f
                            })
                        })
                    },
                    baseURL: backendUrl,
                    bucket: bucket,
                    visibility: 'private',
                    options: {
                        bucket: bucket,
                        visibility: 'private',
                    },
                })

                const { key, extension } = response

                setLocalFiles((prevLocalFiles) => {
                    if (!prevLocalFiles) return prevLocalFiles

                    return prevLocalFiles.map((f) => {
                        if (f.key === file.key) {
                            return {
                                ...f,
                                awsKey: key,
                                extension,
                            }
                        }
                        return f
                    })
                })
            } catch (error) {
                console.log('error', error)
            }
        }

        const uploadFiles = async () => {
            for (const file of localFiles as LocalFile[]) {
                await handleStreamFile(file)
            }
        }

        uploadFiles()
    }, [isUploading, localFiles])

    /*
     * Remove a File
     */

    const handleRemoveFile = (file: LocalFile) => {
        const { key } = file

        const newFiles = localFiles?.filter((f) => f.key !== key) ?? null

        setLocalFiles(newFiles)
    }

    // Handle uploading the awsKey in the report state
    useEffect(() => {
        if (!localFiles) {
            return updateReport('files', [])
        }

        if (localFiles.find((file) => file.progress !== 100)) return

        if (localFiles.find((file) => !file.awsKey)) return

        return updateReport('files', localFiles)

        // eslint-disable-next-line
    }, [localFiles])

    const [isRecordVoiceMessageModalOpen, setIsRecordVoiceMessageModalOpen] =
        useState(false)

    return (
        <>
            {isRecordVoiceMessageModalOpen && (
                <RecordVoiceMessageModal
                    updateReport={updateReport}
                    close={() => setIsRecordVoiceMessageModalOpen(false)}
                />
            )}

            <NewCasePageFrame>
                <NewCasePageFrameHeading>
                    {translation.newCaseFlow.describeTheEvents}
                </NewCasePageFrameHeading>
                <NewCasePageFrameTagline
                    style={{
                        marginBottom: 0,
                    }}
                >
                    {translation.newCaseFlow.theMoreDetailedTheDescription}
                </NewCasePageFrameTagline>
                <AllFieldsTagline>
                    {translation.newCaseFlow.allFieldsWith}
                </AllFieldsTagline>
                <ReportForm>
                    <FormField>
                        <FormLabel>
                            {translation.newCaseFlow.headline}
                        </FormLabel>
                        <FormInput50
                            placeholder={translation.newCaseFlow.enterHeadline}
                            type="text"
                            value={title !== null ? title : ''}
                            onChange={(e) =>
                                updateReport('title', e.target.value)
                            }
                        />
                    </FormField>

                    {reportQuestions.length !== 0 &&
                        reportQuestions.map((questionItem) => {
                            return (
                                <FormField key={questionItem.id}>
                                    <FormLabel>
                                        {questionItem.question}
                                    </FormLabel>
                                    <FormInput50
                                        required
                                        onChange={(e) => {
                                            setCurrentAnswer(questionItem.id)
                                            setAnswer(e.target.value)
                                            handleAnswerChange(
                                                e.target.value,
                                                questionItem
                                            )
                                        }}
                                        onBlur={handleAnswerValidation}
                                    ></FormInput50>
                                    {answerError && (
                                        <ErrorMessage>
                                            {answerError}
                                        </ErrorMessage>
                                    )}
                                </FormField>
                            )
                        })}

                    <FormField>
                        <FormLabel>
                            {translation.newCaseFlow.description}
                        </FormLabel>
                        <FormTextarea
                            placeholder={
                                translation.newCaseFlow.enterDescription
                            }
                            onChange={(e) =>
                                updateReport('description', e.target.value)
                            }
                        >
                            {description}
                        </FormTextarea>

                        {!voiceRecording ? (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '100%',
                                }}
                            >
                                <VoiceButton
                                    onClick={() =>
                                        setIsRecordVoiceMessageModalOpen(true)
                                    }
                                >
                                    <MicIcon />
                                    {translation.newCaseFlow.recordAVoice}
                                </VoiceButton>
                            </div>
                        ) : (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '100%',
                                }}
                            >
                                <DeleteRecordingButton
                                    onClick={deleteRecording}
                                >
                                    <DeleteIcon />
                                </DeleteRecordingButton>

                                <RecordingPreview>
                                    <MicIcon />
                                    {
                                        translation.newCaseFlow
                                            .recordedVoiceMessage
                                    }{' '}
                                    - {formatTime(voiceRecording.length)}
                                </RecordingPreview>
                            </div>
                        )}
                    </FormField>

                    {!localFiles?.length ? (
                        <FormField>
                            <FormLabel>{translation.reusable.files}</FormLabel>
                            <FileInputLabel>
                                <label
                                    htmlFor="file-input"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FileInputLabelHeading>
                                        {translation.newCaseFlow.uploadFiles}
                                    </FileInputLabelHeading>
                                    <FileInputLabelTagline>
                                        {
                                            translation.newCaseFlow
                                                .maximumFileSize
                                        }
                                    </FileInputLabelTagline>
                                </label>
                            </FileInputLabel>

                            <FileInput
                                onChange={(event) => {
                                    handleUploadFiles(event.target.files)
                                }}
                                name="file-input"
                                id="file-input"
                                type="file"
                                multiple
                            />
                        </FormField>
                    ) : (
                        <FormField>
                            <FormLabel>{translation.reusable.files}</FormLabel>
                            <FIleInputLabelWhite>
                                <div
                                    style={{
                                        padding: '1rem 2rem',
                                        marginTop: '-0.5rem',
                                    }}
                                >
                                    {localFiles?.map((file, index) => {
                                        return (
                                            <>
                                                {isRecordVoiceMessageModalOpen && (
                                                    <RecordVoiceMessageModal
                                                        updateReport={
                                                            updateReport
                                                        }
                                                        close={() =>
                                                            setIsRecordVoiceMessageModalOpen(
                                                                false
                                                            )
                                                        }
                                                    />
                                                )}
                                                <FilePreview
                                                    key={index}
                                                    progress={file.progress}
                                                >
                                                    <FileProgressBox
                                                        progress={file.progress}
                                                    />
                                                    {file.file.type ===
                                                    'application/pdf' ? (
                                                        <AiOutlineFilePdf
                                                            size={15}
                                                        />
                                                    ) : file.file.type ===
                                                      'text/csv' ? (
                                                        <FaFileCsv size={15} />
                                                    ) : file.file.type.startsWith(
                                                          'image/'
                                                      ) ? (
                                                        <AiOutlineFileImage
                                                            size={15}
                                                        />
                                                    ) : file.file.type ===
                                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                                        <AiOutlineFileWord
                                                            size={15}
                                                        />
                                                    ) : (
                                                        <FileIcon />
                                                    )}
                                                    <FileName>
                                                        {file.file.name}
                                                    </FileName>
                                                    <FiTrash2
                                                        size={15}
                                                        onClick={() =>
                                                            handleRemoveFile(
                                                                file
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            zIndex: 100,
                                                        }}
                                                    />
                                                </FilePreview>
                                            </>
                                        )
                                    })}
                                </div>
                                <div>
                                    <FileInput
                                        type="file"
                                        name="file-input"
                                        id="file-input"
                                        multiple
                                        onChange={(event) => {
                                            handleUploadFiles(
                                                event.target.files
                                            )
                                        }}
                                    />
                                    <AddFileInputLabel htmlFor="file-input">
                                        {translation.newCaseFlow.addFiles}
                                        <FiPlusCircle />
                                    </AddFileInputLabel>
                                </div>
                            </FIleInputLabelWhite>
                        </FormField>
                    )}

                    <FormField>
                        <TaglineLight>
                            {
                                translation.newCaseFlow
                                    .youShouldBeAwareThatFilesCanContainMetadata
                            }
                        </TaglineLight>
                        <TaglineLight>
                            {translation.newCaseFlow.orisExtraText}
                        </TaglineLight>
                    </FormField>
                </ReportForm>
                <NextButton
                    handleOnClick={updatePage}
                    isActive={title && description ? true : false}
                />
                <InformationYouCouldInclude />
            </NewCasePageFrame>
        </>
    )
}

export default FillInReport
