const se = {
    registerPage: {
        accountType: 'Kontotyp',
        noCreditCardRequired: 'Inget kreditkort krävs, starta din',
        freeTrial: 'kostnadsfria provperiod',
        today: 'idag',
        phoneNumber: 'Telefonnummer',
        software: 'Programvara',
        softwarePlusScreening: 'Programvara + screening',
        easyAndSecure: 'Enkelt och säkert',
        chargedYearly: 'Debiteras årligen',
        secureWhistleblowerPlatform: 'Säker plattform för visselblåsare',
        tailoredWhistleblowerPolicy: 'Skräddarsydd policy för visselblåsare',
        reportManagement: 'Hantering av rapporter',
        followUpCommunication: 'Uppföljande kommunikation',
        emailNotifications: 'E-postmeddelanden',
        employeeGuides: 'Guider för anställda',
        areYouLookingFo: 'Letar du efter vårt partnerprogram?',
        partnerprogrammodal: 'Partnerprogram',
        iHaveAgreedToWalor: 'Jag har läst och godkänner Walors',
        termsAndConditions: 'Villkor och bestämmelser',
        and: 'och',
        privacyAndCookiePolicy: 'Integritets- och cookiepolicy',
        dataProcessingAgreement: 'Avtal om databehandling',
        ifYouAreLookingFor:
            'Om du letar efter ett partnerprogram kan du kontakta oss på',
        thanksCheckYour: 'Klart! Kontrollera din e-post',
        rememberToCheckYourSpam:
            'Fick du inte e-postmeddelandet? Kontrollera din skräppostmapp eller kontakta oss på',
        areYouAMemberOf: 'Är du medlem i',
    },
    onboardingPage: {
        hi: 'Hej',
        welcomeToWalor: ', välkommen till Walor!',
        youAreAlmostDone:
            'Du är nästan klar med att installera din nya lösning',
        insertCompanyInformation: 'Information om företaget',
        subcreateYourCompanyInformation:
            'Lägg till företagets adress och antalet anställda.',
        lineOfContact: 'Kontaktlinje',
        generateWhistleblowerPolicy: 'Visselblåsarpolicy',
        readyToGo: 'Låt oss börja',
        pleaseFillinThisInformation: 'Ange information om ditt företag',
        nextStep: 'Nästa steg',
        name: 'Företagsnamn',
        createYourLineOfContact: 'Skapa en kontaktlinje',
        skipThisStep: 'Hoppa över detta steg',
        subcreateYourLineOfContact:
            'Dessa användare kommer att vara ansvariga för att hantera rapporter om visselblåsning för din organisation.',
        subgenerateyourwbpolicy:
            'Här beskrivs den rättsliga ramen för din lösning för visselblåsning.',
        clickHere: 'Klicka här',
        complete: 'Färdig',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Din visselblåsarpolicy håller på att skapas just nu.',
        goodWork: 'Grattis',
        yourPolicyHasBeen: 'Din visselblåsarpolicy har skapats!',
        allSetEasy: 'Allt är klart! Enkelt, eller hur?',
        letsGetYouStarted: 'Låt oss komma igång direkt',
        takeMeToTheDashboard: 'Ta mig till Dashboard',
    },
    onboardingFlow: {
        searchForCompanyNameOrCompanyNumber:
            'Sök efter företagsnamn eller företagsnummer',
        search: 'Sök',
        enterPhone: '12345678',
        enterZipCode: '1234',
        yourPasswordMustInclude:
            'Lösenordet måste innehålla minst en liten bokstav, en stor bokstav och en siffra.',
        confirmYourPassword: 'Bekräfta lösenordet',
        vat: 'SEK ex. MOMS',
        thanksForYourSubscription: 'Tack för din prenumeration',
        informationOnHowToGetStarted: 'Information om hur du kommer igång',
        linkText:
            'Länken ger dig tillgång till den digitala kanalen för visselblåsarrapporter där rapporter kan skapas och skickas in. Dela detta med din organisation.',
        whistleblowerPolicyText:
            'Ladda ner ditt företags visselblåsarpolicy nedan och logga in på plattformen för ytterligare material.',
        download: 'Ladda ner',
    },
    loginFlow: {
        login: 'Inloggning',
        forgotPassword: 'Har du glömt ditt lösenord?',
        underPageForgotPassword: 'Har du glömt ditt lösenord?',
        underPageWriteYourEmail:
            'Skriv din e-postadress nedan för att skapa ett nytt lösenord',
        howDoYouWant: 'Hur vill du få din säkerhetskod?',
        buttonSMS: 'SMS',
        or: 'eller',
        buttonEmail: 'E-post',
        backToLogin: 'Tillbaka till inloggning',
        enterThe6digit: 'Ange den 6-siffriga koden',
        yourCodeWillArrive: 'Din kod kommer om några sekunder till',
        didYouNotReceive:
            'Har du inte fått koden? Klicka här för att skicka igen',
    },
    whistleblowerCreateCase: {
        whistleblowerScheme: 'Visselblåsarkanal',
        createReport: 'Skapa en rapport',
        startReport: 'Starta en anonym och säker anmälan',
        startReportConfidential: 'Starta en konfidentiell rapport',
        accessExistingReport: 'Få tillgång till en befintlig rapport',
        understandWhistleblowing: 'Vad är visselblåsning?',
        readMore: 'Medarbetarguider',
        playvideo: 'Play',
        howThisWorks: 'Hur det här fungerar',
        createAReport: 'Skapa en rapport',
        createAReportOris:
            'Skapa en anonym eller konfidentiell rapport. Du kan tillhandahålla den information och de filer som du har samlat in. Observera att visselblåsarsystemet är inrättat på frivillig basis och därför inte omfattas av visselblåsarlagen, vilket innebär att (i) rapporter inte får innehålla så kallade ”känsliga personuppgifter”, inklusive information som kan identifiera patienter, och (ii) att det särskilda skydd för visselblåsaren som anges i visselblåsarlagen inte gäller. Som anställd hos ORIS Dentists garanterar vi dig dock ett liknande skydd som en del av din anställning. När du har skickat in rapporten kommer du att få en unik visselblåsarkod som gör att du kan komma åt rapporten igen.',
        createAReportSimply:
            'Skapa en rapport anonymt eller konfidentiellt. Du kan tillhandahålla all information och alla filer som du har samlat in. När du har skickat rapporten får du en unik visselblåsarkod som gör att du kan komma tillbaka till rapporten igen.',
        investigatingthereport: 'Intern utredning',
        caseworkerinthecompany:
            'De handläggare som du väljer för att hantera din rapport kommer att få ett meddelande på sin e-post om att börja genomföra den interna utredningen.',
        answeradditionalquestions: 'Svara på ytterligare frågor',
        additionalQuestions:
            'De handläggare som du väljer kan ha ytterligare frågor till din rapport. Se till att skriva in din rapport då och då för att underlätta den interna utredningen.',
        companiesWhistleblowerPolicy: 'visselblåsarpolicy',
        thePurpose:
            'Syftet med denna kanal för visselblåsarrapporter är att göra det möjligt för anställda att belysa potentiella missförhållanden som annars inte skulle ha upptäckts.',
    },
    whistleblowerHowItWorksPage: {
        backToHomescreen: 'Tillbaka till hemskärmen',
        whistleblowing: 'Visselblåsning i allmänhet',
        whatIsWhistleblowing: 'Vad är visselblåsning?',
        whistleblowingDefinition:
            'Visselblåsning är en persons rapportering av olagliga eller omoraliska handlingar inom en organisation. Den person som rapporterar detta, som kallas visselblåsare, kan antingen vara en del av organisationen eller en tredje part med anknytning till organisationen.',
        whereItsUseful:
            'I de allra flesta fall sker visselblåsning i ett arbetsrelaterat sammanhang. En anställd kan till exempel få information om olaglig verksamhet hos sin arbetsgivare under utförandet av sitt arbete, som den anställde beslutar sig för att rapportera.',
        whatAreTheTypes: 'Vilka typer av visselblåsning finns det?',
        typesOfWhistleblowing:
            'Man kan skilja mellan intern och extern visselblåsning. Intern visselblåsning innebär att en visselblåsare gör en anmälan inom den organisation som han eller hon är en del av eller är relaterad till. Detta innebär att visselblåsaren kommunicerar om missförhållandet endast inom denna organisation. Extern visselblåsning innebär å andra sidan att visselblåsaren uppmärksammar andra än organisationen själv om verksamhet som bedrivs av den organisationen. I det fallet meddelas information till exempelvis offentliga myndigheter eller media.',
        whatIsTheImportance: 'Vilken betydelse har visselblåsning?',
        preventionOrReparation:
            'För det första spelar visselblåsning en viktig roll när det gäller att förebygga eller gottgöra skador på allmänintresset, eftersom personer som ingår i eller är relaterade till en organisation ofta är de första eller enda som har kännedom om eller tillgång till information om sådana skador.',
        ethicCulture:
            'Ur organisationens perspektiv bidrar visselblåsning dessutom till en kultur av etik, laglighet, öppenhet och delaktighet.',
        whistleblowerSafety:
            'Slutligen gör visselblåsning det möjligt för visselblåsaren att övervinna en känsla av skuld eller ansvar efter att ha fått information om olagliga eller omoraliska handlingar i det företag som visselblåsaren är en del av eller har anknytning till.',
        whistleblowerImportance:
            'På grund av denna betydelse finns det ett lagstadgat skydd för visselblåsare.',
        whistleblowerScheme: 'Visselblåsarsystem',
        whatIsWhistleblowerScheme: 'Vad är ett visselblåsarsystem?',
        whistleblowerIsATool:
            'Ett visselblåsarsystem är ett verktyg som gör det möjligt för människor att rapportera om en viss organisation. Systemet vägleder därmed visselblåsare genom processen att rapportera en olaglig eller oetisk verksamhet. Detta gör det möjligt för organisationer att få kännedom om, utreda och åtgärda olagligt eller oetiskt beteende.',
        whyHaveAWhistleBlowerScheme: 'Varför har man ett visselblåsarsystem?',
        whistleblowerLaw:
            'Efter att EU har ökat fokus på värdet av visselblåsning antog EU den 23 oktober 2019 direktiv 2019/1937 om skydd av personer som rapporterar överträdelser av unionsrätten. Detta visselblåsardirektiv, som har genomförts i svensk lag, ger en rättslig ram för visselblåsarsystem och skyddar visselblåsare som rapporterar information genom ett visselblåsarsystem.',
        whistleblowingSchemeTransparency:
            'Detta visselblåsarsystem gör det möjligt för vem som helst att göra sin röst hörd och utöva sin rätt att bli hörd, vilket är viktigt för att skapa en kultur av öppenhet och en känsla av samhörighet. Dessutom stöder och uppmuntrar systemet till att ta upp problem internt, nära problemets källa. Detta gör det möjligt att åtgärda problemen tidigt och effektivt.',
        whoCanUseThisWhistleblowerScheme:
            'Vem kan använda visselblåsarsystemet?',
        anyoneCanUse:
            'Visselblåsarsystemet kan användas av alla som har information om en offentlig eller privat organisation som de har fått i samband med sin arbetsrelaterade verksamhet.',
        categoriesOfPeople:
            'De kategorier av personer som kan använda sig av detta visselblåsarsystem är breda. Anmälningar kan således göras av:',
        people1:
            'Anställda. Detta gäller både hel- och deltidsanställda samt anställda som arbetar på obegränsad eller tidsbegränsad anställning.',
        people2: 'Arbetssökande',
        people3: 'Betalda eller obetalda praktikanter',
        people4: 'Volontärer',
        people5: 'Egenföretagare',
        people6: 'Aktieägare',
        people7: 'Ledamöter i ledningen, styrelsen eller andra styrande organ.',
        people8:
            'Personer som arbetar under övervakning och ledning av entreprenörer, underentreprenörer och leverantörer.',
        noMatterTheRelationship:
            'Personer som omfattas av dessa kategorier kan utnyttja systemet för visselblåsning oavsett om deras anställningsförhållande med organisationen har upphört, fortfarande pågår eller ännu inte har börjat.',
        whatCanBeReported: 'Vad kan rapporteras genom detta visselblåsarsystem',
        seriousViolations:
            'Visselblåsarsystemet kan användas för att ta upp information om allvarliga lagöverträdelser, försök att dölja sådana överträdelser eller andra allvarliga saker som har inträffat eller sannolikt kommer att inträffa i en organisation. Även vid rimliga misstankar om att sådana överträdelser eller frågor förekommer kan denna information rapporteras.',
        violationsOfLaw:
            'De överträdelser som kan rapporteras genom visselblåsarsystemet gäller specifika överträdelser av EU-lagstiftningen eller andra allvarliga brott och ärenden enligt svensk lag. Verksamheter som kan rapporteras genom detta system rör till exempel korruption, miljöskydd, folkhälsa, integritet och personuppgifter eller sexuella trakasserier och sexuella övergrepp. Detta är bara exempel. Även andra ärenden eller lagöverträdelser av allvarlig karaktär kan rapporteras.',
        whatCantBeReported:
            'Vad kan inte rapporteras genom detta visselblåsarsystem?',
        lessSeriousMatters:
            'Detta visselblåsarsystem är inte avsett för att rapportera mindre allvarliga frågor eller vanliga anställningsfrågor, t.ex. missnöje med lönen eller mindre kollegiala frågor. Det är dessutom inte meningen att rapporter som inte lämnas in i god tro eller som uppenbart är ogrundade blir inrapporterade.',
        inCaseOfDoubt:
            'Om du är osäker på om en observation eller misstanke är allvarlig och bör rapporteras eller inte, uppmuntras du ändå att göra det. Alla förfrågningar kommer att besvaras och behandlas.',
        howAreYouProtected: 'Hur skyddas du när du använder detta system?',
        noLiability:
            'Visselblåsare som i god tro rapporterar om allvarliga händelser genom detta system omfattas av ett brett skydd, utan att drabbas av något ansvar för att ha brutit mot tystnadsplikten när det gäller den rapporterade informationen. Även personer som bistår visselblåsaren eller personer eller företag som är relaterade till visselblåsaren omfattas av detta skydd.',
        noRetaliation:
            'Detta skydd innebär ett skydd mot alla former av repressalier. Med repressalier avses varje åtgärd eller underlåtenhet till följd av rapporteringen som orsakar eller kan orsaka omotiverad skada för visselblåsaren. Sådana åtgärder kan ta flera olika former, t.ex. avskedande, byte av arbetsplats, lönesänkning eller skada det personliga eller yrkesmässiga ryktet. Om en visselblåsare eller skyddad person drabbas av repressalier ska han eller hon kompenseras för detta. En organisation som utövar repressalier kan också bli föremål för andra sanktioner.',
        howDoesTheProcessWork: 'Hur fungerar rapporteringsprocessen?',
        howDoIReport: 'Hur rapporterar jag?',
        reportingChannel:
            'Du kan skapa en rapport via den digitala rapporteringskanalen för den organisation som du vill rapportera om.',
        whatIfIncomplete: 'Vad händer om min rapport är ofullständig?',
        accessAndExpand:
            'Det är alltid möjligt att få tillgång till din rapport och att komplettera den med ytterligare uppgifter eller bevis. De personer som handlägger ärendet kan också begära ytterligare frågor eller dokumentation som kan vara relevant för att hantera och utreda rapporten. Om svaren eller dokumentationen senare lämnas in beror dock enbart på din vilja att göra det. Det finns ingen skyldighet att göra det.',
        whoIsProccessing: 'Vem behandlar min rapport?',
        thirdParty:
            'Din anmälan kommer att hanteras av den eller de utsedda personerna i företagets visselblåsnings-enhet. Endast dessa personer är behöriga att genomföra en utredning. Under inga omständigheter kommer ditt ärende att anförtros en person som är inblandad i den rapporterade verksamheten eller som det kan finnas en intressekonflikt med.',
        howIsProccessed: 'Hur behandlas min rapport?',
        investigations:
            'Inom sju dagar får du en bekräftelse på att rapporten mottagits. Därefter görs en första granskning av rapporten. Om rapporten visar sig vara särskilt ogrundad kommer den att avvisas. Du kommer att få ett meddelande om detta så snart som möjligt. Om den första granskningen visar att anmälan inte kan betecknas som uppenbart ogrundad kommer den att utredas ytterligare. Ditt ärende kommer då att behandlas internt. Denna interna utredning kan få konsekvenser för den eller de personer som är inblandade. Om ärendet är av särskilt allvarlig karaktär kan det också överlämnas till polisen för vidare utredning. Efter att ha genomfört alla utredningar och infört eventuella sanktioner kommer ärendet att avslutas. Det kommer då att raderas från systemet.',
        whatIfLooseCode: 'Vad händer om jag förlorar min visselblåsarkod?',
        looseAccess:
            'Om du förlorar din visselblåsarkod kommer du tyvärr inte längre att ha tillgång till din rapport. Detta är den enda möjligheten att garantera en säker och anonym anmälan. Din rapport kommer dock fortfarande att granskas och utredas.',
        unableToUptade:
            'Du kommer inte att kunna uppdatera din rapport eller svara på frågor om rapporten. För att kunna göra detta måste du göra en ny anmälan och skicka in den via detta system för visselblåsning.',
        howLongDoesItTake: 'Hur lång tid tar det att behandla min rapport?',
        whithin3Months:
            'Din anmälan kommer att behandlas och utredas så snart som möjligt. Under denna process kommer du att få återkoppling om eventuella uppdateringar eller resultat. Den totala längden beror på ditt ärende, men du kommer att få information om vilka åtgärder som planeras eller vidtas senast tre månader efter det att du har rapporterat.',
        willIRemainSecret: 'Kommer min identitet att förbli hemlig?',
        anounOrConfidential:
            'Du har möjlighet att rapportera antingen anonymt eller konfidentiellt genom systemet för visselblåsare. Medan det förstnämnda innebär att ingen personlig information delas, innebär det sistnämnda att visselblåsaren delar med sig av personlig information. Om personlig information delas i din rapport kommer endast de som är behöriga att utreda fallet att få tillgång till den. Sekretessen för den inlämnade rapporten kommer därför under alla omständigheter att respekteras.',
        willPersonsBeNotified:
            'Kommer någon av de personer som berörs av en rapport att underrättas?',
        concernedsNotified:
            'Den eller de berörda personerna ska informeras om att de är involverade i en rapport. I varje enskilt fall kommer en bedömning att göras när detta meddelande kan lämnas vidare, så att meddelandet inte får några konsekvenser för insamlingen av bevis.',
        noPersonalInfo:
            'Ingen information, vare sig direkt eller indirekt, kommer att specificeras om vem som gjorde anmälan till den berörda personen, även om du har valt att göra en konfidentiell anmälan och därmed dela med dig av din identitet.',
        isSchemeSecured: 'Är detta visselblåsarsystem säkert?',
        independencyGuarantee:
            'Rapporteringskanalen drivs av Walor ApS, som är en oberoende tredje part som garanterar systemets säkerhet och trygghet. Systemet loggar inte IP-adresser och maskin-ID och krypterar alla uppgifter som behandlas. Alla inblandade parters personuppgiftsrättigheter kommer att respekteras. Endast den ansvariga handläggaren har tillgång till rapporten.',
        whatIfImDissatisfied:
            'Vad händer om jag är missnöjd med bedömningen eller resultatet av min rapport?',
        informCompetentAuth:
            'Det är alltid möjligt att vidta ytterligare åtgärder om du anser att det finns skäl för det. Ett negativt resultat av en rapport får inte hindra dig från att göra detta. Efter att ha rapporterat via systemet för visselblåsare är det möjligt för dig att rapportera informationen till de behöriga myndigheterna. Vid sidan av detta kan rapporter i förekommande fall även göras till Europeiska unionens institutioner, organ, kontor eller byråer.',
    },
    whistleblowerPolicyPage: {
        whistleblowerPolicyHeading: 'Visselblåsarpolicy',
        backToHomescreen: 'Tillbaka till hemskärmen',
        whistleblowerPolicy: ' visselblåsarpolicy',
        thePurposeOfThisWhistleblower:
            'Syftet med denna visselblåsarpolicy är att förklara hur',
        whistleblowerSchemeWorks:
            ' visselblåsarsystem fungerar. Detta system gör det möjligt för dig att rapportera om',
        aGoodUnderstanding:
            'En god förståelse för detta system kan därför förhindra att potentiellt viktiga frågor inte rapporteras.',
        introductionTo: 'Introduktion till',
        whistleblowerScheme: ' visselblåsarsystem',
        isAResponsibleCompanyWith:
            'är ett ansvarsfullt företag med en öppen kultur där alla kan känna sig trygga och uttrycka sig om de upplever att det förekommer oegentligheter eller potentiella brott mot lagen. Som utgångspunkt är det alltid möjligt och stöds starkt att tala med en närmaste chef eller en medlem av ledningen i dessa situationer, men det är också möjligt att använda visselblåsarkanalen för rapportering.',
        whistleblowerSchemeEnsuresProtection:
            ' Visselblåsarsystemet garanterar skydd för personer som anmäler allvarliga brott och ärenden. Detta system kan användas om du upplever, är medveten om eller har skäl att misstänka att sådana överträdelser förekommer i följande fall',
        bothInThePast:
            'både i det förflutna, i nutid och i en nära framtid. Rapporter kan lämnas in anonymt eller konfidentiellt.',
        whoCanUseThisWhistleblowingScheme:
            'Vem kan använda Visselblåsarsystemet?',
        whistleblowerSchemeCanBeUsedByAnyone:
            ' visselblåsarsystem kan användas av alla som har information om följande',
        whichTheyHaveAcquired:
            ' som de har förvärvat inom ramen för sin arbetsrelaterade verksamhet med',
        theCategoriesOfPeopleWho:
            'De kategorier av personer som kan använda sig av detta visselblåsarsystem är breda. Anmälningar kan således göras av:',
        employees:
            'Anställda. Detta gäller både hel- och deltidsanställda samt anställda som arbetar på obegränsad eller tidsbegränsad anställning.',
        jobApplicants: 'Arbetssökande',
        paidOrUnpaidTrainees: 'Betalda eller obetalda praktikanter',
        volunteers: 'Volontärer',
        selfEmployedPersons: 'Egenföretagare',
        shareholders: 'Aktieägare',
        membersOfTheManagement:
            'Ledamöter i ledningen, styrelsen eller andra styrande organ i',
        personsWorkingUnder:
            'Personer som arbetar under övervakning och ledning av entreprenörer, underentreprenörer och leverantörer av',
        personsFallingUnderTheseCategories:
            'Personer som omfattas av dessa kategorier kan utnyttja visselblåsarsystemet oavsett om deras anställningsförhållande med',
        hasCeased:
            'har upphört, fortfarande pågår eller ännu inte har påbörjats.',
        whatCanYouReport: 'Vad kan du rapportera?',
        theWhistleblowerSchemeCanBeUsed:
            'Visselblåsarsystemet kan användas för att ta upp information om allvarliga överträdelser av lagen, försök att dölja sådana överträdelser eller andra allvarliga saker som har inträffat eller sannolikt kommer att inträffa i',
        alsoInTheCaseOfRasonable:
            'Om det finns rimliga misstankar om att dessa överträdelser eller frågor förekommer kan denna information också rapporteras. Det krävs dock att rapporterna lämnas in i god tro och att detta rapporteringssystem inte används för ogrundade anklagelser mot oskyldiga personer.',
        theViolationsWhich:
            'De överträdelser som kan rapporteras genom visselblåsarsystemet gäller specifika överträdelser av EU-lagstiftningen eller andra allvarliga brott och ärenden enligt svensk lag. Mindre allvarliga frågor eller vanliga anställningsfrågor, t.ex. missnöje med lönen eller mindre kollegiala frågor, är inte avsedda att rapporteras genom detta visselblåsarsystem.',
        activitesThatCanBeReported:
            'De aktiviteter som kan rapporteras genom detta system rör till exempel korruption, miljöskydd, folkhälsa, integritet och personuppgifter eller sexuella trakasserier och sexuella övergrepp. Detta är bara exempel. Även andra frågor eller lagöverträdelser av allvarlig karaktär kan rapporteras.',
        ifYouAreInDoubtAbout:
            'Om du är osäker på om din observation eller misstanke är av allvarlig art och bör rapporteras eller inte, uppmuntras du att göra det. Alla förfrågningar kommer att besvaras och behandlas.',
        protectionOfWhistleblowers: 'Skydd av visselblåsare',
        anyoneWhoReportsMatters:
            'Den som i god tro rapporterar om sådant som beskrivs ovan är skyddad från alla former av repressalier. Åtgärder för repressalier innebär direkta eller indirekta åtgärder eller försummelser som inträffar i ett arbetsrelaterat sammanhang som ett resultat av rapporteringen och som orsakar eller kan orsaka omotiverad skada för visselblåsaren. Sådana åtgärder kan ta sig flera olika uttryck, t.ex. avskedande, byte av arbetsplats, lönesänkning eller skada visselblåsarens rykte.',
        ifAWhistleblowerSuffers:
            'Om en visselblåsare utsätts för någon form av repressalier av',
        heOrSheWillBeCompensated:
            'kommer han eller hon att få ersättning för detta. Vid uppsägning ska uppsägningen ogiltigförklaras och anställningsförhållandet ska bibehållas eller återupprättas om visselblåsaren så önskar.',
        mayAlsoBeSubjectToOtherSanctions:
            'kan också bli föremål för andra sanktioner.',
        howToReport: 'Hur ska man rapportera?',
        reportsThatLieWithin:
            'Rapporter som omfattas av denna policy behandlas av den eller de personer som valts ut i företagets överenskomna kontaktlinje, dvs:',
        onlyThesePersonsAreAuthorised:
            'Endast dessa personer är behöriga att genomföra en utredning. I vissa fall kan utredningen dock genomföras med hjälp av externa parter, t.ex. advokater eller revisorer.',
        howAreTheReportsProcessed: 'Hur behandlas rapporterna?',
        youWilRecieveAnAcknowledgement:
            'Inom sju dagar får du en bekräftelse på att rapporten mottagits. Därefter görs en första granskning av rapporten. Om rapporten visar sig vara särskilt ogrundad kommer den att avvisas. Den person som anmälde fallet kommer att underrättas om detta så snart som möjligt. Om den inledande granskningen visar att rapporten inte kan betecknas som särskilt ogrundad kommer den att utredas ytterligare. Ärendet kommer då att behandlas internt. Informanten kommer att få återkoppling om eventuella uppdateringar eller resultat så snart som möjligt. Denna interna utredning kan få konsekvenser för den eller de personer som är inblandade. Om ärendet är av särskilt allvarlig karaktär kan det också överlämnas till polisen för vidare utredning. Efter att ha genomfört alla utredningar och infört eventuella påföljder kommer ärendet att avslutas. Det kommer då att raderas från systemet.',
        subsequentClarificationAndCollection:
            'Efterföljande förtydligande och insamling av ytterligare dokumentation.',
        whenYouCreateAReport:
            'När du skapar en rapport får du möjlighet att logga in i systemet och se om handläggaren har ställt ytterligare frågor om ärendet eller begärt ytterligare dokumentation. Eventuell efterföljande dialog beror enbart på visselblåsarens vilja att logga in i systemet och svara på handläggarens frågor.',
        confidentialityAndITSecurity: 'Konfidentialitet och IT-säkerhet',
        aReportCanBeMadeEither:
            'En anmälan kan göras antingen anonymt eller konfidentiellt. Medan det förstnämnda innebär att ingen personlig information delas, innebär det sistnämnda att visselblåsaren delar med sig av personlig information. Om personlig information delas i din anmälan är det endast de som är behöriga att utreda ärendet som har tillgång till den. Sekretessen för den inlämnade rapporten kommer därför under alla omständigheter att respekteras.',
        theWhistleblowerReportingChannel:
            'Visselblåsar-rapporteringskanalen drivs av Walor ApS, som är en oberoende tredje part som garanterar systemets säkerhet och trygghet. Systemet loggar inte IP-adresser och maskin-ID och krypterar alla uppgifter som behandlas. Endast den ansvariga handläggaren har tillgång till rapporten.',
        noticeOfPersonConcerned: 'Meddelande till den berörda personen',
        theRecipientOfTheReport:
            'Mottagaren av rapporten är skyldig att förse den person som rapporteras med information om den rapporterade händelsen. I varje enskilt fall görs en bedömning när denna anmälan kan vidarebefordras, så att anmälan inte får några konsekvenser för bevisinsamlingen.',
        noInformationEitherDirect:
            'Ingen information, vare sig direkt eller indirekt, kommer att specificeras om vem som har gjort anmälan till den berörda personen, även om anmälaren har valt att rapportera konfidentiellt.',
        questions: 'Frågor',
        allQuestions: 'Alla frågor om visselblåsarsystemet kan ställas till',
    },
    newCaseFlow: {
        orisExtraText: 'BS inga personuppgifter om patienter',
        headerInitiateReport: 'Inled en rapport',
        headerDescription: 'Beskrivning',
        headerChooseCaseworker: 'Välj handläggare',
        headerSummary: 'Sammanfattning',
        initiateReport: 'Inled en rapport',
        howWouldYouLikeToAppear: 'Hur vill du att du ska visas i rapporten?',
        or: 'eller ',
        confidential: 'Konfidentiellt',
        whatIstheDifferenceBetweenAnAnonymous:
            'Vad är skillnaden mellan en anonym och en konfidentiell anmälan?',
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'Vad är skillnaden mellan en anonym och en konfidentiell anmälan?',
        popUpAnonymousYouProvideYourEmployer:
            'Du ger information om både det potentiella missförhållandet, den observerade händelsen eller olyckan, men du behöver INTE lämna några personuppgifter alls.',
        popUpConfidential: 'Konfidentiellt',
        popUpConfidentialYouProvideYourEmployer:
            'Du ger information om både det potentiella missförhållandet, den observerade händelsen eller olyckan och även information om dig själv.',
        confidentialInformation: 'Konfidentiell information',
        provideYourPersonalInformation:
            'Lämna dina personuppgifter till rapporten',
        secretInformationNote:
            'Din information kommer att hållas hemlig och kan endast ses av visselblåsarenheten.',
        enterPhone: '12345678',
        describeTheEvents: 'Beskriv händelserna',
        theMoreDetailedTheDescription:
            'Ju mer detaljerad beskrivningen är, desto lättare är det att utreda fallet.',
        allFieldsWith: 'Alla fält med * är obligatoriska',
        headline: 'Rubrik *',
        enterHeadline: 'Rubrik',
        description: 'Beskrivning *',
        enterDescription: 'Beskrivning',
        recordAVoice: 'Spela in ett röstmeddelande',
        recordYourVoice: 'Spela in ditt röstmeddelande',
        yourVoiceIsAutomatically:
            'Din röst förvrängs automatiskt för att säkerställa din anonymitet',
        listenToYourRecording: 'Lyssna på din inspelning innan du bifogar den.',
        clickToStartRecording: 'Klicka för att starta inspelningen',
        voiceMessage: 'Röstmeddelande',
        stopRecording: 'Stoppa inspelningen',
        addRecording: 'Lägg till röstmeddelande',
        recordedVoiceMessage: 'Inspelat röstmeddelande',
        voiceRecording: 'Röstmeddelande',
        uploadFiles: 'Ladda upp filer',
        maximumFileSize: 'Maximal filstorlek: 100 MB',
        addFiles: 'Lägg till filer',
        youShouldBeAwareThatFilesCanContainMetadata:
            'Du bör vara medveten om att filer kan innehålla metadata som kan äventyra din anonymitet.',
        informationYouCould: 'Information som du kan inkludera',
        whereAndWhen: 'Var och när ägde händelsen rum?',
        whatIsYourKnowledge: 'Vad vet du om fallet?',
        whatDepartmentIsInvolved: 'Vilken avdelning är inblandad?',
        whatIsYourRelationship: 'Vad har du för relation till organisationen?',
        reportSummary: 'Sammanfattning av rapporten',
        summaryCaseWorker: 'Vem kommer att få rapporten?',
        summaryHowWouldYouLikeToAppear:
            'Hur vill du att du ska visas i rapporten?',
        summaryHeadline: 'Rubrik',
        summaryDescription: 'Beskrivning',
        summaryFiles: 'Filer',
        expectToRecieveAnAnswer: 'Förvänta dig att få svar inom 7 dagar',
        submitReport: 'Skicka in rapport',
        theReportHasBeenReceived: 'Rapporten har mottagits.',
        inOrderToAccessYourCaseAgain:
            'Du har nu möjlighet att komma åt din rapport genom att använda den här koden.',
        keepItSafe:
            'Se till att förvara den på ett säkert sätt och undvik att dela den med andra.',
        whistleblowerCode: 'Visselblåsarkod',
        copyToClipboard: 'Kopiera kod',
        stayUpdated: 'Håll dig uppdaterad',
        youHaveTheOpportunityToReceiveAMail:
            'Få uppdateringar genom att ange din e-post.',
        itIsImportantToMention: 'Din e-post kommer inte att delas med andra.',
        theWhistleblowerCodeWillNot:
            'Visselblåsarkoden kommer INTE att skickas per post.',
        email: 'Din e-postadress',
        chooseContactFrame: {
            pickYourCaseworker: 'Välj din handläggare',
            pickExcludedCaseworker:
                'Handläggare som kommer att ta emot rapporten',
            theContactWill: 'Den valda kontakten kommer att få rapporten.',
            theContactWillNot: 'Handläggare som kommer att ta emot rapporten',
            languages: 'Språk',
            languagesNotGiven: 'Språk ej angivet',
            pickCaseworker: 'Välj handläggare',
        },
        popUpRememberYourWhistleblowerCode: 'Kom ihåg visselblåsarkoden',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'Det är viktigt att du sparar och lagrar visselblåsarkoden, så att du kan få tillgång till ärendet i framtiden.',
    },
    accessCaseFlow: {
        accessExistingReport: 'Öppna befintlig rapport',
        inOrderToAccessYourCase:
            'Använd din privata visselblåsarkod för att få tillgång till din befintliga rapport.',
        missingCode: 'Har du glömt din kod?',
        createNewRaport: 'Vänligen skapa en ny rapport.',
        enterYourWhistleblowerCode: 'Ange din privata visselblåsarkod',
        enterCode: 'Ange kod',
        logout: 'Logga ut',
        info: 'Info',
        id: 'ID',
        date: 'Datum',
        appearance: 'Visibilitet',
        caseworkers: 'Handläggare',
        category: 'Kategori',
        files: 'Filer',
        fromWhistleblower: 'Från visselblåsare',
        fromCaseworker: 'Från handläggare',
        timeline: 'Tidslinje',
        description: 'Beskrivning',
        messages: 'Meddelanden',
        translate: 'Översätt',
        translated: 'Översatt',
        caseworker: 'Handläggare',
        enterMessage: 'Ange meddelande',
        send: 'Skicka',
        logoutOfReport: 'Logga ut ur rapporten',
        hiddenMsgOne: 'Detta är datumet då rapporten skapades',
        hiddenMsgTwo:
            'Handläggaren ska ge dig ett första svar senast vid denna tidsfrist',
        hiddenMsgThree:
            'Handläggaren måste ge dig feedback senaste vid denna tidsfrist',

        months: {
            january: 'Januari',
            february: 'Februari',
            march: 'Mars',
            april: 'April',
            may: 'Maj',
            june: 'Juni',
            july: 'Juli',
            august: 'Augusti',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    header: {
        dashboard: 'Dashboard',
        cases: 'Ärenden',
        clients: 'Kunder',
        settings: 'Inställningar',
        notifications: 'Meddelanden',
        readAll: 'Läs allt',
    },
    dashboardPartner: {
        headlineDashboard: 'Dashboard',
        yourcases: 'Dina fall',
        id: 'ID',
        company: 'Företag',
        headline: 'Rubrik',
        status: 'Status',
        notifications: 'Meddelanden',
        all: 'Alla',
        unread: 'Oläst',
        noCases: 'Du har inga fall',
        noNotifications: 'Du har inga meddelanden',
        yourStatistics: {
            yourStatistics: 'Din statistik',
            open: 'Öppna',
            closed: 'Stängt',
            forwarded: 'Vidarebefordrad',
            totalCases: 'Totalt antal fall',
            activeCases: 'Aktiva ärenden',
            closedCases: 'Avslutade ärenden',
            forwardedCases: 'Vidarebefordrade ärenden',
        },
    },
    casesPartner: {
        headlineCases: 'Fall',
        allCases: 'Alla fall',
        id: 'ID',
        company: 'Företag',
        headline: 'Rubrik',
        responsible: 'Ansvarig',
        status: 'Status',
        filter: 'Filter',
        removeFilter: 'Ta bort filter',
        myCases: 'Mina fall',
        actionRequired: 'Kräver handläggning',
        awaitingResponse: 'Väntar på svar',
        seeClosedCases: 'Visa avslutade ärenden',
        viewForwardedCases: 'Visa vidarebefordrade ärenden',
        forwardedCases: 'Vidarebefordrade ärenden',
        searchCases: 'Sök efter fall',
        headlineClosedCases: 'Avslutade ärenden',
        closedCases: 'Avslutade ärenden',
        caseClosed: 'Fallet är avslutat',
    },
    caseworkerOpenReport: {
        transcription: 'Transkription',
        goBack: 'Gå tillbaka',
        reportBy: 'Rapport från',
        days: 'dagar',
        hours: 'timmar',
        thisReportHasBeen: 'Denna rapport har skickats av',
        sevenDaysMessageOne:
            'Du måste ge en bekräftelse på att du har tagit emot en rapport inom sju dagar efter att du har tagit emot den.',
        sevenDaysMessageTwo:
            'Skicka ett meddelande till visselblåsaren nedan och följ lagen.',
        ninetyDaysMessageOne:
            'Visselblåsaren bör få återkoppling så snart som möjligt och senast tre månader efter det att han eller hon fått bekräftelse på att rapporten mottagits.',
        ninetyDaysMessageTwo:
            'Återkoppling kan till exempel vara planen för eller resultatet av en internutredning.',
        name: 'Namn',
        exportCase: 'Överför',
        editInformation: 'Redigera information',
        changeResponsibility: 'Ansvarig för förändringen',
        theNewResponsible: 'Den nya ansvarige kommer att meddelas via e-post.',
        thereAreNoOther: 'Det finns inga andra handläggare tillgängliga',
        forward: 'Framåt',
        title: 'Titel',
        uncategorized: 'Okategoriserade',
        categorizeCase: 'kategorisera fall',
        selectCategory: 'Välj en kategori för det här fallet',
        selectedCategory: 'Den valda kategorin',
        close: 'Stäng',
        frequentCategories: 'Frekventa kategorier',
        allCategories: 'Alla kategorier',
        ViewMoreCategories: 'Visa fler kategorier',
        ViewLess: 'Visa mindre',
        saveThis: 'Spara',
        status: 'Status',
        changeStatus: 'Ändra status',
        youAreInTheProcess: 'Du håller på att ändra status till',
        awaitingResponse: 'Väntar på svar',
        actionRequired: 'Kräver handläggning',
        confirm: 'Bekräfta',
        caseDescription: 'Beskrivning',
        noFilesUploaded: 'Inga filer har laddats upp',
        historyLog: 'Ärendehistorik',
        preferredContact: 'Föredragen kontakt',
        noMessages: 'Inga meddelanden',
        caseworker: 'Handläggare',
        whistleblower: 'Visselblåsare',
        enterMessage: 'Ange meddelande',
        internalNotes: 'Interna anteckningar',
        takeNotesHere:
            'Lägg till dina anteckningar här, så att du inte glömmer dem.',
        onlyTheCaseworkers: 'Detta är endast synligt för handläggarna.',
        enterNote: 'Skriv en anteckning',
        saveNote: 'Spara',
        closeCase: 'Stäng fallet',
        solved: 'Löst',
        denied: 'Avvisade',
        forwarded: 'Vidarebefordrad',
        addComment: 'Lägg till en kommentar',
        forwardedAt: 'Vidarebefordrat till',
        severityScore: 'Svårighetsgrad',
        comment: 'Kommentar',
        theCaseHasBeenForwardedTo: 'Ärendet har överlämnats till',
        theCaseHasBeenForwardedBy: 'Ärendet har översänts av',
        caseClosed: 'Fallet är avslutat',
        toEnsureGDPR:
            'För att säkerställa GDPR-efterlevnad kommer detta fall att raderas permanent om',
        theOptionToExportTheCase:
            'Möjligheten att exportera fallet till en PDF är tillgänglig inom denna tidsram.',
        theDeletionOfTheCaseWillNot:
            'Raderingen av fallet kommer inte att påverka din statistiköversikt.',

        caseworkerPopup: {
            editCaseworker: 'Redigera handläggare',
            close: 'Stäng',
            caseworkerInCharge: 'Ansvarig för ärendet',
            newFlag: 'Ny',
            addCaseworkers: 'Lägg till handläggare',
            noCaseworkerAvailable:
                'Det finns ingen annan lämplig handläggare som kan tilldelas detta ärende.',
            discardChanges: 'Kassera ändringar',
        },
    },
    caseClosedViewPartner: {
        closeDate: 'Slutdatum',
        comment: 'Kommentar',
        info: 'Info',
        id: 'ID',
        appearance: 'Utseende',
        responsible: 'Ansvarig',
        company: 'Företag',
        description: 'Beskrivning',
        caseworker: 'Handläggare',
        internalNotes: 'Interna anteckningar',
        historyLog: 'Ärendehistorik',
        automaticDelation: 'Automatisk radering',
        exportCase: 'Export fall',
        caseWillBePermanentlyDeleted:
            'För att säkerställa överensstämmelse med GDFR, kommer detta fall att raderas permanent',
        readMore: 'Läs mer',
        noComment: 'Ingen kommentar',

        internalNote: 'Intern anteckning',
        internalNoteHasBeen: 'En intern anteckning har skrivits av',
        caseworkerAdded: 'Handläggare tillagd',
        wasAddedBy: 'lades till av',
        newFile: 'Ny fil',
        newFileWasAdded: 'En ny fil har lagts till av',
        whistleblower: 'visselblåsare',
        automaticReplySent: 'Automatiskt svar skickat',
        automaticReplyWasSent: 'Automatiskt svar skickades av',
    },
    clientsPartner: {
        headlineClients: 'Klienter',
        buttonAddNewClient: 'Lägg till en ny klient',
        allClients: 'Alla klienter',
        name: 'Namn',
        openCases: 'Öppna ärenden',
        closeCases: 'Avslutade ärenden',
        newCases: 'Nya fall',
    },
    singleClientViewPartner: {
        information: 'Information',
        lineOfContact: 'Kontaktlinje',
        cases: 'Fall',
        invoices: 'Fakturor',
        whistleblowerPolicy: 'Visselblåsarpolicy',
        contactPerson: 'Kontaktperson',
        fullName: 'Fullständigt namn',
        name: 'Namn',
        enterPhone: '12345678',
        addLineOfContact: 'Lägg till en ärendehandläggare',
        inviteCaseworker: 'Bjud in handläggare',
        saveOrderOfContacts: 'Spara ordningen på kontakterna',
        lineOfContactsHaveNot: 'Kontaktlinjer har inte skapats.',
        allCases: 'Alla fall',
        id: 'ID',
        company: 'Företag',
        headline: 'Rubrik',
        responsible: 'Ansvarig',
        status: 'Status',
        filter: 'Filter',
        removeFilter: 'Ta bort filter',
        myCases: 'Mina fall',
        actionRequired: 'Kräver handläggning',
        awaitingResponse: 'Väntar på svar',
        seeClosedCases: 'Visa avslutade ärenden',
        description: 'Beskrivning',
        addLanguage: 'Lägg till språk',
        searchLanguage: 'Sök språk',
        languageNotFound: 'språket hittades inte',

        // Export and reopen case Modal
        reOpenCase: 'Öppna fallet igen',
        youAreInTheProcessOfExporting: 'Du är i färd med att exportera fallet',
        youAreInTheProcessOfReopening: 'Du är i färd med att öppna fallet',
        caseInfo: 'Information om fallet',
        arrivalDate: 'Ankomstdatum',
        closeDate: 'Stängningsdatum',
        closer: 'Stäng handläggare',
        caseID: 'Fall-ID',
        deleteCaseworker: 'Radera handläggaren',
        youAreAboutToDeleteTheCaseworker:
            'Du är på väg att radera handläggaren',
        theCaseworkerWillNotAbleToReceive:
            'Handläggaren kommer inte längre att kunna ta emot framtida rapporter',
        theCaseworkerCannotBeDeletedIf:
            'Handläggaren kan inte raderas om denne är kopplad till ett öppet fall',
        thisActionCannotBeUndone: 'Denna handling kan inte ångras',
        caseworkerHasCasesAndCannotBeDeleted:
            'Handläggaren har ett eller flera aktiva fall och kan inte raderas',
        contactAdded: 'En ny handläggare har tillkommit',
        contactUpdated: 'Handläggare uppdaterad',
    },
    addNewClientPartner: {
        headlineAddNewClient: 'Lägg till en ny klient',
        searchForCompanyNameOrCompanyNumber:
            'Sök efter företagsnamn eller företagsnummer',
        findCompany: 'Hitta företag',
        enterInformationManually: 'Ange information manuellt',
        enterPhone: '12345678',
        enterZipCode: '1234',
        yourPasswordMustInclude:
            'Lösenordet måste innehålla minst en liten bokstav, en stor bokstav och en siffra.',
        thanksForYourSubscription: 'Du har lagt till en ny klient',
        informationOnHowToGetStarted:
            'Information om hur klienten kan komma igång',
        linkText:
            'Länken ger dig tillgång till den digitala kanalen för visselblåsarrapportering där rapporter kan skapas och skickas in. Dela detta med klienten.',
        employeeLink: 'Länk till anställda',
        whistleblowerPolicyText:
            'Hitta klientens policy för visselblåsare nedan och hänvisa klienten att logga in på plattformen för ytterligare material.',
        whistleblowerPolicy: 'Policy för visselblåsare',
        download: 'Ladda ner',
        sendLinkText: 'Skicka informationen till din klient',
        buttonSendEmail: 'Skicka e-post',
        popUpSendEmail: 'Skicka e-post',
        popUpText:
            'Du är på väg att skicka länken för visselblåsar-rapporteringskanalen och visselblåsarpolicyn till',
        popUpButtonSendInformation: 'Skicka information',
        popUpAreYouSureYouWantToCancelThisSession:
            'Är du säker på att du vill avbryta den här sessionen?',
        popUpYouWillLoseAllYourProgress: 'Du förlorar alla dina framsteg',
        popUpButtonNo: 'Nej',
        popUpButtonYes: 'Ja',
    },
    settingsPartner: {
        headlineSettings: 'Inställningar',
        myAccount: 'Mitt konto',
        automaticReply: 'Automatiskt svar',
        billing: 'Fakturering',
        users: 'Användare',
        invoices: 'Fakturor',
        logOut: 'Logga ut',
        edit: 'Redigera',
        oldPassword: 'Gammalt lösenord',
        changePassword: 'Ändra lösenord',
        newPassword: 'Nytt lösenord',
        passwordUpdate: 'Färdig!',
        reactivateAutoReply: 'Återaktivera automatiskt svar',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        autoReplyAlertMessage:
            'Det automatiska svaret kommer att skickas 15 minuter efter mottagandet av rapporten',
        passwordUpdadetSuccessfully: 'Lösenordet har uppdaterats.',
        yourPasswordMustInclude:
            'Lösenordet måste innehålla minst en liten bokstav, en stor bokstav och en siffra.',
        emailUpdated: 'E-postadressen har blivit uppdaterad!',
        verificationLinkSent:
            'En verifieringslänk har skickats till den nya e-postadressen. Du kommer att loggas ut om',
        seconds: 'sekunder.',
        notifications: 'Meddelanden',
        defaultLanguage: 'Standardspråk',
        newCase: 'Nytt fall',
        name: 'Namn',
        added: 'Tillagd',
        openCases: 'Öppna ärenden',
        closedCases: 'Avslutade ärenden',
        buttonAddNewUser: 'Lägg till en ny användare',
        popUpAddingANewUser: 'Lägga till en ny användare',
        popUpAddPhoto: 'Lägg till foto',
        popUpChangePhoto: 'Ändra foto',
        popUpText:
            'Användaren måste verifiera sitt konto med ett aktiveringsmeddelande.',
        popUpButtonAddUser: 'Lägg till användare',
        oldAddPaymentMethodFrame: {
            billing: 'Fakturering',
            paymentMethods: 'Fakturering',
            addPaymentMethod: 'Lägg till betalningsmetod',
            defaultPaymentMethod: 'Standardbetalningsmetod',
            yourDefaultPaymentMethod:
                'Standardbetalningsmetoden kommer att användas för alla framtida betalningar. Om du har en prenumerationsavgift att betala kommer vi att försöka debitera den automatiskt.',
            defaultPaymentMethodMissing: 'Standardbetalning saknas',
            otherPaymentMethods: 'Andra betalningsmetoder',
            otherPaymentMethodsYouHave:
                'Andra betalningsmetoder som du har lagt till. Markera den som du vill använda som standard.',
            currentPlan: 'Nuvarande plan',
            belowYouCanSee: 'Här kan du se den valda abonnemangsplanen.',
            cardNumber: 'Kortnummer',
            expireDate: 'Förfallodatum',
            addPaymentMethodModal: {
                addPaymentMethod: 'Lägg till betalningsmetod',
                card: 'Kort',
                invoice: 'Faktura',
                errorLoadingStripe: 'Error, loading Stripe',
                fullName: 'Fullständigt namn',
                bankInformation: 'Bankinformation',
                cardInformation: 'Information om kortet',
                loginMessage:
                    'Detta kan ta några minuter. Vänligen ha tålamod.',
            },
            flashMessages: {},
        },
        userInformation: 'Användarinformation',
        description: 'Beskrivning',
    },
    clientView: {
        willBeDisplayedReportLink:
            'Informationen nedan kommer att visas för visselblåsaren i rapporteringskanalen',
        languages: 'Språk',
        notes: 'Anteckningar',
        preview: 'Förhandsgranskning',
        pickCaseworker: 'Välj din handläggare',
        chosenContactWillReceive: 'Den valda kontakten kommer att få rapporten',
        editProfilePicture: 'Redigera profilbild',
        addPhoto: 'Lägg till bild',
        changePhoto: 'Ändra bild',
        information: 'Information',
        lineOfContact: 'Ärendehandläggare',
        customization: 'Anpassning',
        invoices: 'Fakturor',
        whistleblowerPolicy: 'Visselblåsarpolicy',
        reportingChannel: 'Rapporteringskanal',
        copyLinkReportingChannel: 'Kopiera länk till din rapporteringskanal',
        downloadQRCodeImage: 'Ladda ner bild här',
        qrCode: 'QR-Kod',
        visitReportLink:
            'Klicka här för att gå till länken för företagsrapportering',
        downloadQRCode: 'Ladda ner QR-koden för rapporteringskanalen',
        copyLinkWhistleblowerPolicy: 'Kopiera länk till din visselblåsarpolicy',
        trainingMaterial: 'Utbildningsmaterial',
        copyLinkToTrainingMaterial:
            'Kopiera länk till ditt utbildningsmaterial',
        reportingChannelLanguage: 'Rapporteringskanalens språk:',
        reportingChannelLanguageError: 'rapporteringskanal språkfel:',

        contactPerson: 'Kontaktperson',
        fullName: 'Fullständigt namn',
        name: 'Namn',
        enterPhone: '12345678',
        addQuestion: 'Lägg till en fråga',
        reportQuestions: 'Frågor om rapporten',
        description: 'Beskrivning',
    },
    verifyEmail: {
        verifyEmail: 'Verifiera e-post',
        yourPasswordMust:
            'Lösenordet måste bestå av minst 8 tecken. Det måste också innehålla 1 liten bokstav, 1 stor bokstav, 1 siffra och 1 symbol.',
        emailVerified: 'E-post är verifierad',
        yourEmailHasBeen:
            'Din e-post har verifierats, du kan nu gå till inloggningssidan och logga in med din e-postadress och ditt nyskapade lösenord.',
        goToLogin: 'Gå till inloggning',
        verifyNewEmail: 'Bekräftad!',
        loginWithNewEmail: 'Du kan nu logga in med den nya e-postadressen.',
    },
    nextButton: {
        nextStep: 'Nästa',
    },
    finishButton: {
        finish: 'Slutför',
    },
    closeButton: {
        close: 'Stäng',
    },
    submitButton: {
        submit: 'Skicka in',
    },
    loginButton: {
        login: 'Inloggning',
    },
    sendButton: {
        send: 'Skicka',
    },
    dropdown: {
        countryNotFound: 'Landet hittades inte',
        search: 'Sök',
    },
    copiedText: {
        copied: 'Kopierad',
    },
    you: {
        you: 'Du',
    },
    warningText: {
        warning: 'Varning',
    },
    deleteText: {
        delete: 'Radera',
    },
    inactiveModal: {
        inactivityWarning: 'Varning för inaktivitet',
        youWillBeLoggedOut: 'Du kommer att loggas ut',
        youHaveBeenInactive:
            'Du har varit inaktiv i 14 minuter. När timern löper ut kommer du att loggas ut.',
        stayLoggedIn: 'Håll dig inloggad',
    },
    notifications: {
        newMessage: 'Nytt meddelande',
        newFile: 'Ny fil',
        deadlineAlert: 'Varning för deadline',
        newReport: 'Ny rapport',
        reportAssigned: 'Rapport som tilldelats',
        reportUnassigned: 'Rapportera ej tilldelade',
        statusUpdated: 'Status uppdaterad',
    },
    historyLog: {
        forwarded: 'Vidarebefordrad',
        exported: 'Överförd',
        changeResponsible: 'Ansvarig för förändringen',
        internalNote: 'Intern anmärkning',
        caseViewed: 'Fall som granskats',
        fileUploaded: 'Uppladdad fil',
        newMessage: 'Nytt meddelande',
        statusUpdated: 'Status uppdaterad',
        caseDelegation: 'Delegering av ärenden',
        reportCreated: 'Rapport skapad',
        to: 'till',
        by: 'av',
        from: 'från',
        caseHasBeenForwarded: 'Ärendet har vidarebefordrats',
        caseHasBeenExported: 'Fallet har överförts',
        caseHasBeenReassigned: 'Ärendet har omfördelats',
        caseHasBeenViewed: 'Fallet har setts',
        categoryIsChanged: 'Kategorin är ändrad',
        messageFrom: 'Meddelande från',
        caseAssigned: 'Tilldelat ärende',
        newCase: 'Nytt fall',
    },
    validation: {
        nameOfTheCityMustBeAtLeast:
            'Stadens namn måste bestå av minst 1 tecken.',
        nameOfTheCityCannot: 'Stadens namn får inte vara längre än 85 tecken.',
        companyIdentificationNumberMust:
            'Numret måste vara exakt 8 tecken långt.',
        addressIsToShort: 'Adressen är för kort',
        addressIsToLong: 'Adressen är för lång',
        organizationIsToShort: 'Organisationen är för kort',
        organizationIsToLong: 'Organisationen är för lång',
        mustBeAValidEmail: 'Måste vara en giltig e-postadress',
        firstNameCannotBeShorter: 'Förnamnet får inte vara kortare än 2 tecken',
        firstNameCannotBeLonger: 'Förnamnet får inte vara längre än 26 tecken',
        lastNameCannotBeShorter:
            'Efternamnet får inte vara kortare än 2 tecken',
        lastNameCannotBeLonger: 'Efternamnet får inte vara längre än 26 tecken',
        passwordMustBeAtLeast: 'Lösenordet måste bestå av minst 8 tecken',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Lösenordet måste innehålla minst en stor bokstav.',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Lösenordet måste innehålla minst en liten bokstav.',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Lösenordet måste innehålla minst en symbol',
        yourPasswordMustContainerAtLeastOneNumber:
            'Lösenordet måste innehålla minst en siffra och en bokstav',
        passwordsMustMatch: 'Lösenorden måste matcha',
        notAValidPhoneNumber: 'Inte ett giltigt telefonnummer',
        titleOfPositionMustBe:
            'Positionens titel måste vara längre än 2 tecken.',
        titleOfPositionCannotBe:
            'Positionens titel får inte vara längre än 26 tecken.',
        mustSetAPriority: 'Du måste ange en prioritet',
        notAValidZipCode: 'Inte ett giltigt postnummer',
        isLongEnough: 'Minst 8 tecken lång',
        hasUpperCaseLetter: 'Minst 1 stor bokstav [A-Z].',
        hasLowerCaseLetter: 'Minst 1 liten bokstav [a-z].',
        hasNumber: 'Minst 1 siffra [0-9]',
        hasSpecialCharacter: 'Minst 1 specialtecken[!@#$%^&*()\\-__+.]',
        passwordsMatch: 'Båda lösenorden matchar',
    },
    forwardReportPage: {
        selectCatagory: 'Välj kategori',
        uncategorized: 'Okategoriserade',
        accusationAndEvidenceScore: 'Anklagelse och bevisvärde',
        accusationScore: 'Poäng för anklagelser',
        evidenceScore: 'Poäng för bevis',
        severityScore: 'Total svårighetsgrad',
        lineOfContact: 'Kontaktlinje',
        lineOfContactReminder:
            'Kontrollera att personen inte på något sätt är inblandad i detta fall.',
        comment: 'Kommentar',
        pleaseLeaveComment: 'Lämna en kommentar här',
        forwardCase: 'Vidarebefodra fall',
        popUpConfirmForwardPartOne: 'Är du säker på att du vill vidarebefordra',
        popUpConfirmForwardPartTwo: 'till',
        popUpNo: 'Nej',
        popUpYes: 'Ja, det är jag säker på.',
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Max',
        saveThis: 'Spara detta',
    },
    trialWarning: {
        goToSettings: 'Gå till inställningar',
        yourTrialHasEnded: 'Din provperiod har avslutats.',
        yourFreeTrialEndedOn: 'Din kostnadsfria provperiod avslutades den:',
        yourFreeTrialEndsOn: 'Prövningsperioden löper ut om',
        day: 'dag',
        days: 'dagar',
    },
    categories: {
        accounting_irregularities: 'Bokföringsmässiga oegentligheter',
        bribery: 'Mutor',
        consumer_protection: 'Konsumentskydd',
        duty_of_confidentiality: 'Tystnadsplikt',
        embezzlement: 'Förskingring',
        extortion: 'Utpressning',
        financial_services_products_and_markets:
            'Finansiella tjänster, produkter och marknader',
        food_safety_animal_health_and_welfare:
            'Livsmedelssäkerhet, djurhälsa och djurskydd',
        forgery: 'Förfalskning',
        fraudulence: 'Bedrägeri',
        money_laundering_and_terrorist_financing:
            'Penningtvätt och finansiering av terrorism',
        occupational_health_and_safety: 'Hälsa och säkerhet på arbetsplatsen',
        products_safety_and_compliance: 'Produkters säkerhet och efterlevnad',
        protection_of_environment: 'Skydd av miljön',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Skydd av privatlivet och personuppgifter samt nätverks- och informationssystemens säkerhet.',
        public_health: 'Folkhälsa',
        public_procurement: 'Offentliga upphandlingar',
        radiation_protection_and_nuclear_safety: 'Strålskydd och kärnsäkerhet',
        serious_or_recurring_conflicts_at_the_workplace:
            'Allvarliga eller återkommande konflikter på arbetsplatsen.',
        sexual_harassment_and_sexual_assault:
            'Sexuella trakasserier och sexuella övergrepp',
        the_financial_interests_of_the_european_union:
            'Europeiska unionens ekonomiska intressen',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'Europeiska unionens inre marknad, inklusive statligt stöd och företagsbeskattning.',
        theft: 'Stöld',
        conflict_of_interest: 'Intressekonflikter',
        transport_safety: 'Transportsäkerhet',
        non_whistleblower_case: 'Icke-fall av visselblåsare',
    },
    integrationModal: {
        thisAppWouldLikeToAccess:
            'This app would like to access your Walor account. You need to give permission to do this',
        thisAppRequires: 'This app requires permission to',
        whistleblowingChannel: 'whistleblowing channel',
        whistleblowingPolicy: 'whistleblowing policy',
        employeeGuides: 'employee guides',

        approve: 'Approve',
    },

    dacarpoPolicy: {
        itIsImportant:
            'It is important that you save the whistleblower code from the report so you can access the case afterwards.',
        allQuestions:
            'All questions about the whistleblower scheme can be directed to our HR department.',

        // info list
        youWillReceive:
            'You will receive an acknowledgement of receival of the report within seven days.',
        afterThis:
            'After this, an initial examination of the report will be conducted.',
        ifTheReport:
            'If the report turns out to be notably unfounded, it will be rejected. The person who reported the case will be no@fied of this as soon as possible',
        howeverIfTheInitial:
            'However, if the initial examination determines that the report cannot be characterized as notably unfounded, it will be further investigated.',
        theCaseWillThen:
            'The case will then be processed internally. The whistleblower will receive feedback on any updates or outcomes as soon as possible.',
        thisInternalInvestigation:
            'This internal investigation may have consequences for the person or persons who are involved.',
        ifTheCaseIs:
            'If the case is of particular serious nature, it may also be passed on to the police for further investigation',
        afterConducting:
            'After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
    },

    inviteCaseworkerModal: {
        // MODAL
        inviteNewCaseworker: 'Bjud in en ny handläggare',
        sendInvitation: 'Skicka inbjudan',
    },

    caseworkerSetupAccountPage: {
        // Caseworker onboarding page
        setupYourWalor: 'Skapa ditt konto hos Walor',
        youHaveBeenInvited: 'Du har blivit inbjuden att ansluta dig',
        onWalorAs: 'på Walor som en',
        caseworker: 'handläggare',
        additionalInformation: 'Övriga uppgifter',
        preview: 'Förhandsvisning',

        // Personal Information Form
        // Additional information form
        profilePicture: 'Profilbild',
        thisPictureWillAppear:
            'Denna bild kommer visas när visselblåsaren väljer handläggare.',
        addPicture: 'Lägg till bild',
        notes: 'Anteckningar',
        typeNoteHere: 'Skriv en anteckning här..',
        addLanguages: 'Lägg till språk',
        whichDepartmentAreYou: 'Vilken avdelning tillhör du',
        chooseOneOption: 'Välj ett alternativ',
        humanResources: 'Human Resources',
        compliance: 'Compliance',
        finance: 'Ekonomi',
        operations: 'Operativa verksamheten',
        marketing: 'Marknadsföring',
        other: 'Övriga',
        //Preview
        languages: 'Språk',
        pickCaseworker: 'Välj handläggare',
        // Notes hover-effect
        notesHover: 'Anteckningarna kommer att visas för visselblåsaren',
        // Languages hover-effect
        languagesHover:
            'Ange vilka språk du talar så att visselblåsaren kan välja den handläggare som är bäst lämpad',
    },
    aalborgTeaterPolicy: {
        whistleblowerSchemeEnsuresProtection:
            's whistleblowerordning sikrer beskyttelse af personer, der indberetter om alvorlige lovovertrædelser og forhold. Denne ordning kan anvendes, hvis du oplever, er bekendt med eller har grund til at formode, at sådanne overtrædelser der har fundet sted hos',
        theWhistleblowerSchemeCanBeUsed:
            'Whistleblowerordningen kan bruges til at behandle oplysninger om alvorlige overtrædelser af loven, forsøg på at skjule sådanne overtrædelser eller øvrige alvorlige forhold, som har fundet sted eller med stor sandsynlighed vil finde sted hos',
        aReportCanBeMadeEither:
            'En indberetning kan kun ske fortrolligt, hvilket indebærer at whistlebloweren videregiver personlige oplysninger. Hvis der deles personlige oplysninger i din indberetning, vil kun de personer, der er autoriseret til at undersøge sagen, have adgang til dem. Fortroligheden af den indsendte indberetning vil derfor under alle omstændigheder blive respekteret.',
    },
    reusable: {
        voiceRecording: 'Röstinspelning',
        email: 'E-post',
        personalInformation: 'Personlig information',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        cancel: 'Avbryt',
        address: 'Adress',
        city: 'Stad',
        zipCode: 'Postnummer',
        country: 'Land',
        numberOfEmployees: 'Antal anställda',
        phone: 'Telefonnummer',
        position: 'Position',
        companyInformation: 'Företagsinformation',
        companyNumber: 'Företagsnummer',
        companyName: 'Företagsnamn',
        workEmail: 'Företagsmail',
        enterWorkEmail: 'Företagsmail',
        password: 'Lösenord',
        anonymous: 'Anonymt',
        files: 'Filer',
        date: 'Datum',
        messages: 'Meddelanden',
        organization: 'Organisation',
        new: 'Ny',
        search: 'Sök på',
        category: 'Kategori',
        save: 'Spara',
        confirmPassword: 'Bekräfta lösenordet',
        name: 'Namn',

        closed: 'Stängt',
        archived: 'Arkiverad',
    },

    statisticsPage: {
        statistics: 'Statistik',
        overview: 'Översikt',
        compareToLastPeriod: 'Jämför med senaste perioden',
        noData: 'Ingen data',
        lastYear: 'föregående år',
        anonymousVsConfidential: 'Anonym vs. konfidentiell',
        actionStats: 'Handläggningsstatistik',
        avgDaysToFirstResponse: 'Genomsnittligt antal dagar till första svar',
        avgDaysToClosedCase: 'Genomsnittligt antal dagar till avslutat fall',
        distributionOfCategories: 'Fördelning av kategorier',
        casesByMonth: 'Antal ärenden per månad',
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'Maj',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Okt',
            nov: 'Nov',
            dec: 'Dec',
        },
    },
}

export default se
