const en = {
    registerPage: {
        accountType: 'Account type',
        noCreditCardRequired: 'No credit card required, start your',
        freeTrial: 'free trial',
        today: 'today',
        firstname: 'First name',
        phoneNumber: 'Phone number',
        software: 'Software',
        softwarePlusScreening: 'Software + Screening',
        easyAndSecure: 'Easy and secure',
        chargedYearly: 'Charged yearly',
        secureWhistleblowerPlatform: 'Secure whistleblower platform',
        tailoredWhistleblowerPolicy: 'Tailored whistleblower policy',
        reportManagement: 'Report management',
        followUpCommunication: 'Follow-up communication',
        emailNotifications: 'E-mail notifications',
        employeeGuides: 'Employee guides',
        areYouLookingFo: 'Are you looking for our partner program?',
        partnerprogrammodal: 'Partner Program',
        iHaveAgreedToWalor: 'I have read and agree to Walor’s',
        termsAndConditions: 'Terms & conditions',
        and: 'and',
        privacyAndCookiePolicy: 'Privacy and cookie policy',
        dataProcessingAgreement: 'Data Processing Agreement',
        ifYouAreLookingFor:
            'If you are looking for a partner program contact us at',
        thanksCheckYour: 'Done! Check your email',
        rememberToCheckYourSpam:
            "Didn't receive the email? Please check your spam folder or contact us at",
        areYouAMemberOf: 'Are you a member of',
    },
    onboardingPage: {
        hi: 'Hi',
        welcomeToWalor: ', welcome to Walor!',
        youAreAlmostDone: 'You are almost done setting your new solution up',
        insertCompanyInformation: 'Company Information',
        subcreateYourCompanyInformation:
            'Add the company address and the number of employees.',
        generateWhistleblowerPolicy: 'Whistleblower Policy',
        readyToGo: "Let's Begin",
        pleaseFillinThisInformation: 'Enter your company information',
        nextStep: 'Next step',
        name: 'Company name',
        createYourLineOfContact: 'Create your Line of Contact',
        skipThisStep: 'Skip this step',
        subcreateYourLineOfContact:
            'These users will be responsible for handling whistleblowing reports for your organization.',
        subgenerateyourwbpolicy:
            'This describes the legal framework of your whistleblowing solution.',
        clickHere: 'Click here',
        complete: 'Complete',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Your Whistleblower Policy is being generated as we speak',
        goodWork: 'Congratulations',
        yourPolicyHasBeen: 'Your Whistleblower Policy has been created!',
        allSetEasy: 'All set! Easy, right?',
        letsGetYouStarted: "Let's get you started right away",
        takeMeToTheDashboard: 'Take me to the Dashboard',
    },
    onboardingFlow: {
        enterCompanyNumber: 'Company number',
        enterCompanyName: 'Company name',
        enterPhone: '12345678',
        enterAddress: 'Address',
        enterCity: 'City',
        enterZipCode: '1234',
    },
    loginFlow: {
        login: 'Login',
        forgotPassword: 'Forgot password?',
        underPageForgotPassword: 'Forgot password?',
        underPageWriteYourEmail:
            'Write your email below to create a new password',
        howDoYouWant: 'How do you want to receive your security code?',
        buttonEmail: 'Email',
        buttonSMS: 'SMS',
        or: 'or',
        backToLogin: 'Back to login',
        enterThe6digit: 'Enter the 6-digit code',
        yourCodeWillArrive: 'Your code will arrive in a few seconds to',
        didYouNotReceive: 'Did you not receive the code? Click here to re-send',
    },
    whistleblowerCreateCase: {
        whistleblowerScheme: 'Whistleblower Reporting Channel',
        createReport: 'Create report',
        startReport: 'Initiate an anonymous and safe report',
        startReportConfidential: 'Start a confidential report',
        accessExistingReport: 'Access existing report',
        understandWhistleblowing: 'Understand whistleblowing',
        readMore: 'Employee guides',
        playvideo: 'Play',
        howThisWorks: 'How this works',
        createAReport: 'Create a report',
        createAReportOris:
            'Create an anonymous or confidential report. You can provide the information and files you have collected. Please note that the whistleblower scheme is established on a voluntary basis and is therefore not covered by the Whistleblower Act with the consequence that (i) reports may not contain so-called ‘sensitive personal data’ including information that can identify patients, and (ii) that the special protection for the whistleblower as set out in the Whistleblower Act does not apply. However, as an employee of ORIS Dentists, we guarantee you similar protection as part of your employment. Once you have submitted the report, you will receive a unique whistleblower code that allows you to access the report again.',
        createAReportSimply:
            'Create a report anonymously or confidentially. You can provide all the information and files you have gathered. You will get a unique whistleblowercode, once you have sent the report, that allows you to access the report again.',
        investigatingthereport: 'Internal investigation',
        caseworkerinthecompany:
            'The caseworkers that you choose to handle your report will receive a notification on their email to start conducting the internal investigation.',
        answeradditionalquestions: 'Answer additional questions',
        additionalQuestions:
            'The caseworkers that you choose might have additional questions to your report. Make sure to re-enter your report once in a while to aid in the internal investigation.',
        companiesWhistleblowerPolicy: 'Whistleblower Policy',
    },
    whistleblowerHowItWorksPage: {
        whistleblowing: 'Whistleblowing in general',
        whatIsWhistleblowing: 'What is whistleblowing? ',
        whistleblowingDefinition:
            'Whistleblowing is the reporting by an individual of illegal or immoral actions within an organisation. The person reporting this, called a whistleblower, can either be part of this organisation or a third-party related to the organisation.',
        whereItsUseful:
            'In the vast majority of the cases, whistleblowing will take place in a work-related context. An employee might for example acquire information about illegal activities of his or her employer during the performance of his or her work, which the employee decides to report.',
        whatAreTheTypes: 'What types of whistleblowing exist?',
        typesOfWhistleblowing:
            'A distinction can be made between internal and external whistleblowing. Internal whistleblowing entails that a whistleblower makes a report within the organisation he or she is part of or related to. This means that the whistleblower communicates about the misconduct only within this organisation. On the other hand, external whistleblowing means that the whistleblower alerts others than the organisation itself of activities by that organisation. In that case, information is communicated to for example public authorities or the media.',
        whatIsTheImportance: 'What is the importance of whistleblowing?',
        preventionOrReparation:
            'First of all, whistleblowing plays an essential role in the prevention or reparation of harms to the public interest, as persons who are part of or related to an organisation are often the first or only to have knowledge or access about information of such harms.',
        ethicCulture:
            'Next to this, from the perspective of the organisation, whistleblowing contributes to a  culture of ethics, legality, transparency and participation.',
        whistleblowerSafety:
            'Lastly, whistleblowing allows the whistleblower to overcome a feeling of guilt or sense of responsibility after acquiring information about illegal or immoral actions in the company the whistleblower is part of or related to.',
        whistleblowerImportance:
            'Because of this importance, protection for whistleblowers exists by law.',
        whistleblowerScheme: 'Whistleblower scheme',
        whatIsWhistleblowerScheme: 'What is a Whistleblower scheme?',
        whistleblowerIsATool:
            'A whistleblower scheme is a tool which allows people to make reports about a certain organisation. This system thereby guides whistleblowers through the process of reporting an illegal or unethical activity. This enables organisations to learn about, investigate and remedy illegal or unethical conduct.',
        whyHaveAWhistleBlowerScheme: 'Why have a whistleblower scheme?',
        whistleblowerLaw:
            'Following an increased focus of the European Union on the value of whistleblowing, the EU adopted Directive 2019/1937 on the Protection of Persons Who Report Breaches of Union Law on October 23, 2019. This Whistleblower Directive, which has been implemented into Danish law, provides a legal framework for whistleblowing schemes and protects whistleblowers that report information through a whistleblower scheme.',
        whistleblowingSchemeTransparency:
            'This whistleblowing scheme enables anyone to have a voice and exercise their right to be heard, which is essential to create a culture of transparency and a sense of belonging. Next to this, this scheme supports and encourages to raise concerns internally, close to the source of the problem. This makes it possible to remedy these concerns early and effectively.',
        whoCanUseThisWhistleblowerScheme:
            'Who can use this whistleblower scheme?',
        anyoneCanUse:
            'The whistleblower scheme can be used by anyone who has information about a public or private organisation which they have acquired in the course of his or her work-related activities.',
        categoriesOfPeople:
            'The categories of people who can use this whistleblowing scheme are broad. Reports can thus be filed by:',
        people1:
            'Employees. This entails both full and part-time employees as well as employees working under an unlimited or fixed-term contract.',
        people2: 'Job applicants',
        people3: 'Paid or unpaid trainees',
        people4: 'Volunteers',
        people5: 'Self-employed persons',
        people6: 'Shareholders',
        people7:
            'Members of the management, supervisory board or other governing board',
        people8:
            'Persons working under the supervision and direction of contractors, subcontractors and suppliers',
        noMatterTheRelationship:
            'Persons falling under these categories can make use of the whistleblowing scheme regardless of whether their employment relationship with the organisation has ceased, is still ongoing or has not yet begun.',
        whatCanBeReported:
            'What can be reported through this whistleblower scheme?',
        seriousViolations:
            'The whistleblower scheme can be used to address any information about serious violations of the law, attempts to conceal such violations or other serious matters that have occurred or are likely to occur in an organisation. Also in the case of reasonable suspicion of the occurrence of these violations or matters, this information can be reported.',
        violationsOfLaw:
            'The violations which can be reported through the whistleblower scheme relate to specific breaches of EU law or other serious offenses and matters according to Danish law. Activities that can be reported through this scheme relate for example to corruption, protection of the environment, public health, privacy and personal data or sexual harassment and sexual abuse. These are only examples. Also other matters or violations of the law of a serious nature can be reported.',
        whatCantBeReported:
            'What can not be reported through this whistleblower scheme?',
        lessSeriousMatters:
            'This whistleblower scheme is not meant to report less serious matters or ordinary employment issues, such as dissatisfaction with salary or minor collegial issues. Next to this, reports that are not submitted in good faith and which are manifestly unfounded are not meant to be submitted.',
        inCaseOfDoubt:
            'If you are in doubt whether an observation or suspicion is of a serious nature and should be reported or not, you are still encouraged to do so. All inquiries will be answered and processed.',
        howAreYouProtected: 'How are you protected when using this scheme?',
        noLiability:
            'Whistleblowers that report matters of a serious nature in good faith through this scheme enjoy broad protection, without incurring any liability for breaching a duty of confidentiality with regards to the reported information. Also persons assisting the whistleblower or persons or companies related to the whistleblower fall under this protection.',
        noRetaliation:
            'This protection entails a protection against any form of retaliation. Retaliations entail any action or omission as a result from the report and which cause or may cause unjustified harm to the whistleblower. Such actions can take multiple forms, such as for example dismissal, change of location of work, reduction in wages or harming the personal or professional reputation. If a whistleblower or protected person suffers any retaliation he or she will be compensated for this. An organisation that retaliates may also be subject to other sanctions.',
        howDoesTheProcessWork: 'How does the process of reporting work?',
        howDoIReport: 'How do I report?',
        reportingChannel:
            'You can create a report through the digital reporting channel of the organisation you intend to report about.',
        whatIfIncomplete: 'What if my report is incomplete?',
        accessAndExpand:
            'It is always possible to access your report and to expand it by providing additional details or evidence. The persons handling the case might also request additional questions or documentation, which may be relevant to handle and investigate the report. A subsequent submission of answers or documentation solely depends however on your willingness to do so. No obligation to do so exists.',
        whoIsProccessing: 'Who is processing my report?',
        thirdParty:
            "Your report will be handled by the selected person(s) in the company's whistleblowing unit. Only these persons are authorized to conduct an investigation. Under no circumstances will your case be entrusted to a person involved in the reported activities or with whom there may be a conflict of interest.",
        howIsProccessed: 'How is my report processed?',
        investigations:
            'You will receive an acknowledgement of receival of the report within seven days. After this, an initial examination of the report will be conducted. If the report turns out to be notably unfounded, it will be rejected. You will receive a notice of this as soon as possible. However, if the initial examination determines that your report cannot be characterized as notably unfounded, it will be further investigated. Your case will then be processed internally. This internal investigation may have consequences for the person or persons who are involved. If the case is of particular serious nature, it may also be passed on to the police for further investigation. After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
        whatIfLooseCode: 'What if I lose my whistleblower code?',
        looseAccess:
            'Unfortunately, in the event of losing the whistleblower code, you will not have access anymore to your report. This is the only possibility to guarantee a secure and anonymous report. Your report will still be reviewed and investigated however.',
        unableToUptade:
            'You will not be able to update your report or answer any received questions about the report. In order to do this,  a new report will have to be made and submitted through this whistleblowing scheme.',
        howLongDoesItTake: 'How long will it take to process my report?',
        whithin3Months:
            'Your report will be processed and investigated as soon as possible. During this process, you will receive feedback on any updates or outcomes. The total duration will depend on your case but you will receive at the latest within three months after reporting information on the action envisaged or taken.',
        willIRemainSecret: 'Will my identity remain secret?',
        anounOrConfidential:
            'You have the opportunity to report either anonymously or confidential through the whistleblower scheme. Whereas the former entails that no personal information is shared, the latter includes the sharing of personal information by the whistleblower. If personal information is shared in your report, only those who are authorized to investigate the case will have access thereto. The confidentiality of the submitted report will therefore in any case be respected.',
        willPersonsBeNotified:
            'Will any of the persons concerned in a report be notified?',
        concernedsNotified:
            'The person or persons concerned will be informed of his or her involvement in a report. In each individual case, an assessment will be made of when this notice can be passed on, so that the notice will have no consequences for the collection of evidence.',
        noPersonalInfo:
            'No information, either direct or indirect, will be specified about who made the report towards the person concerned, even if you have chosen to report confidentially and therefore share your identity.',
        isSchemeSecured: 'Is this whistleblower scheme secured?',
        independencyGuarantee:
            'The whistleblower reporting channel is operated by Walor ApS, which is an independent third party who guarantees the safety and security of the system. The system does not log IP addresses and machine-IDs and will encrypt all data processed. The personal data rights of all parties involved will be respected. Only the responsible caseworker has access to the report.',
        whatIfImDissatisfied:
            'What if I am dissatisfied with the assessment or outcome of my report?',
        informCompetentAuth:
            'It is always possible to take further action if you consider grounds thereto exist. In no case a negative outcome of a report prevents you from doing so. After reporting through the whistleblower scheme, it is possible for you to report the information to the competent authorities. Next to this, where relevant, reports can also be made to institutions, bodies, offices or agencies of the European Union.',
    },
    whistleblowerPolicyPage: {
        whistleblowerPolicyHeading: 'Whistleblower policy',
        backToHomescreen: 'Back to homescreen',
        whistleblowerPolicy: "'s whistleblower policy",
        thePurposeOfThisWhistleblower:
            'The purpose of this whistleblower policy is to explain how',
        whistleblowerSchemeWorks:
            "'s whistleblower scheme works. This scheme allows you to make reports about",
        aGoodUnderstanding:
            'A good understanding of this scheme can therefore prevent potentially important matters from being left unreported.',
        introductionTo: 'Introduction to',
        whistleblowerScheme: "'s whistleblower scheme",
        isAResponsibleCompanyWith:
            'is a responsible company with an open culture, where everyone can feel safe and express themselves if they experience wrongdoing or potential breaches of the law. As a starting point, it is always possible and strongly supported to talk to an immediate superior or a member of the Managment (Executive Board) in these situations, but it is also possible to use the whistleblower reporting channel.',
        whistleblowerSchemeEnsuresProtection:
            "'s whistleblower scheme ensures protection for individuals who report serious offenses and matters. This scheme can be used if you experience, are aware of or have reasons to suspect the occurrence of such breaches in",
        bothInThePast:
            'both in the past, present or near future. Reports can be filed either anonymously or confidential.',
        whoCanUseThisWhistleblowingScheme:
            'Who can use this Whistleblowing scheme?',
        whistleblowerSchemeCanBeUsedByAnyone:
            "'s whistleblower scheme can be used by anyone who has information about",
        whichTheyHaveAcquired:
            'which they have acquired in the course of his or her work-related activities with',
        theCategoriesOfPeopleWho:
            'The categories of people who can use this whistleblowing scheme are broad. Reports can thus be filed by:',
        employees:
            'Employees. This entails both full and part-time employees as well as employees working under an unlimited or fixed-term contract.',
        jobApplicants: 'Job applicants',
        paidOrUnpaidTrainees: 'Paid or unpaid trainees',
        volunteers: 'Volunteers',
        selfEmployedPersons: 'Self-employed persons',
        shareholders: 'Shareholders',
        membersOfTheManagement:
            'Members of the management, supervisory board or other governing board of',
        personsWorkingUnder:
            'Persons working under the supervision and direction of contractors, subcontractors and suppliers of',
        personsFallingUnderTheseCategories:
            'Persons falling under these categories can make use of the whistleblowing scheme regardless of whether their employment relationship with',
        hasCeased: 'has ceased, is still ongoing or has not yet begun.',
        whatCanYouReport: 'What can you report?',
        theWhistleblowerSchemeCanBeUsed:
            'The whistleblower scheme can be used to address any information about serious violations of the law, attempts to conceal such violations or other serious matters that have occurred or are likely to occur in',
        alsoInTheCaseOfRasonable:
            'Also in the case of reasonable suspicion of the occurrence of these violations or matters, this information can be reported. It is required however that reports are submitted in good faith, and that this reporting scheme is not used for unfounded accusations towards innocent people.',
        theViolationsWhich:
            'The violations which can be reported through the whistleblower scheme relate to specific breaches of EU law or other serious offenses and matters according to Danish law. Less serious matters or ordinary employment issues, such as dissatisfaction with salary or minor collegial issues are not meant to be reported through this whistleblower scheme.',
        activitesThatCanBeReported:
            'Activities that can be reported through this scheme relate for example to corruption, protection of the environment, public health, privacy and personal data or sexual harassment and sexual abuse. These are only examples. Also other matters or violations of the law of a serious nature can be reported.',
        ifYouAreInDoubtAbout:
            'If you are in doubt whether your observation or suspicion is of a serious nature and should be reported or not, you are encouraged to do so. All inquiries will be answered and processed.',
        protectionOfWhistleblowers: 'Protection of whistleblowers',
        anyoneWhoReportsMatters:
            'Anyone who reports matters as described above in good faith is protected from any form of retaliation. Actions of retaliation entail direct or indirect actions or omissions which occur in a work-related context as a result from the report and which cause or may cause unjustified harm to the whistleblower. Such actions can take multiple forms, such as for example dismissal, change of location of work, reduction in wages or harming the reputation of the whistleblower.',
        ifAWhistleblowerSuffers:
            'If a whistleblower suffers any form of retaliation by',
        heOrSheWillBeCompensated:
            'he or she will be compensated for this. In case of dismissal, the dismissal shall be annulled and the employment relationship shall be maintained or re-established if the whistleblowers so wishes.',
        mayAlsoBeSubjectToOtherSanctions:
            'may also be subject to other sanctions.',
        howToReport: 'How to report?',
        reportsThatLieWithin:
            'Reports that lie within the scope of this policy are processed by the chosen person or persons in the company’s agreed line of contact, which are:',
        onlyThesePersonsAreAuthorised:
            'Only these persons are authorized to conduct an investigation. However, in some cases, the investigation may be carried out with the help of external parties, such as lawyers or auditors.',
        howAreTheReportsProcessed: 'How are the reports processed?',
        youWilRecieveAnAcknowledgement:
            'You will receive an acknowledgement of receival of the report within seven days. After this, an initial examination of the report will be conducted. If the report turns out to be notably unfounded, it will be rejected. The person who reported the case will be notified of this as soon as possible. However, if the initial examination determines that the report cannot be characterized as notably unfounded, it will be further investigated. The case will then be processed internally. The whistleblower will receive feedback on any updates or outcomes as soon as possible. This internal investigation may have consequences for the person or persons who are involved. If the case is of particular serious nature, it may also be passed on to the police for further investigation. After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
        subsequentClarificationAndCollection:
            'Subsequent clarification and collection of additional documentation',
        whenYouCreateAReport:
            "When you create a report, you get the opportunity to log in to the system and see if the caseworker has asked additional questions about the case or requests additional documentation. Any subsequent dialogue depends solely on the whistleblower's willingness to log in to the system and answer the caseworker's questions.",
        confidentialityAndITSecurity: 'Confidentiality and IT security',
        aReportCanBeMadeEither:
            'A report can be made either anonymous or confidential. Whereas the former entails that no personal information is shared, the latter includes the sharing of personal information by the whistleblower. If personal information is shared in your report, only those who are authorised to investigate the case will have access thereto. The confidentiality of the submitted report will therefore in any case be respected.',
        theWhistleblowerReportingChannel:
            'The whistleblower reporting channel is operated by Walor ApS, which is an independent third party who guarantees the safety and security of the system. The system does not log IP addresses and machine-IDs and will encrypt all data processed. Only the responsible caseworker has access to the report.',
        noticeOfPersonConcerned: 'Notice of person concerned',
        theRecipientOfTheReport:
            'The recipient of the report is obliged to provide the person being reported with information about the reported event. In each individual case, an assessment will be made of when this notice can be passed on, so that the notice will have no consequences for the collection of evidence.',
        noInformationEitherDirect:
            'No information, either direct or indirect, will be specified about who made the report towards the person concerned, even if the reporter has chosen to report confidentially.',
        questions: 'Questions',
        allQuestions:
            'All questions about the whistleblower scheme can be directed to',
    },
    newCaseFlow: {
        orisExtraText: 'BS no personal data regarding patients',
        headerInitiateReport: 'Initiate report',
        headerDescription: 'Description',
        headerChooseCaseworker: 'Choose caseworker',
        headerSummary: 'Summary',
        initiateReport: 'Initiate report',
        howWouldYouLikeToAppear: 'How would you like to appear in the report?',
        or: 'or',
        confidential: 'Confidential',
        whatIstheDifferenceBetweenAnAnonymous:
            'What is the difference between an anonymous and a confidential report?',
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'What is the difference between an anonymous and a confidential report?',
        popUpAnonymous: 'Anonymous',
        popUpAnonymousYouProvideYourEmployer:
            'You provide information about both the potential wrongdoing, observed incident or accident but you do NOT need to provide any of your personal information at all.',
        popUpConfidential: 'Confidential',
        popUpConfidentialYouProvideYourEmployer:
            'You provide information about both the potential wrongdoing, observed incident or accident as well as some information about yourself.',
        confidentialInformation: 'Confidential Information',
        provideYourPersonalInformation:
            'Provide your personal information to the report',
        secretInformationNote:
            'Your information will be kept secret and can only be viewed by the whistleblower unit.',
        enterPhone: '12345678',
        describeTheEvents: 'Describe the events',
        theMoreDetailedTheDescription:
            'The more detailed the description is, the easier it is to investigate the case',
        allFieldsWith: 'All fields with * are required',
        headline: 'Headline *',
        enterHeadline: 'Headline',
        description: 'Description *',
        enterDescription: 'Description',
        recordAVoice: 'Record a voice message',
        recordYourVoice: 'Record your voice message',
        yourVoiceIsAutomatically:
            'Your voice is automatically distorted for anonymity',
        listenToYourRecording: 'Listen to your recording before attaching it.',
        clickToStartRecording: 'Click to start recording',
        voiceMessage: 'Voice message',
        stopRecording: 'Stop recording',
        addRecording: 'Add recording',
        recordedVoiceMessage: 'Recorded voice message',
        voiceRecording: 'Voice recording',
        uploadFiles: 'Upload files',
        maximumFileSize: 'Maximum file size: 100 MB',
        addFiles: 'Add files',
        youShouldBeAwareThatFilesCanContainMetadata:
            'You should be aware that files can contain metadata, which could potentially compromise your anonymity.',
        informationYouCould: 'Information you could include',
        whereAndWhen: 'Where and when did the event take place?',
        whatIsYourKnowledge: 'What is your knowledge of the case?',
        whatDepartmentIsInvolved: 'Which department is involved?',
        whatIsYourRelationship:
            'What is your relationship with the organization?',
        reportSummary: 'Report summary',
        summaryCaseWorker: 'Who will recieve the report',
        summaryHowWouldYouLikeToAppear:
            'How would you like to appear in the report?',
        summaryHeadline: 'Headline',
        summaryDescription: 'Description',
        summaryFiles: 'Files',
        expectToRecieveAnAnswer: 'Expect to receive an answer within 7 days',
        submitReport: 'Submit report',
        theReportHasBeenReceived: 'The report has been received',
        inOrderToAccessYourCaseAgain:
            'You can now access your report using the following code.',
        keepItSafe: 'Keep it safe and do not share it with anyone.',
        whistleblowerCode: 'Whistleblower Code',
        copyToClipboard: 'Copy code',
        stayUpdated: 'Stay updated',
        youHaveTheOpportunityToReceiveAMail:
            'Receive updates by entering your email.',
        itIsImportantToMention: 'Your email will not be shared.',
        theWhistleblowerCodeWillNot:
            'The Whistleblower Code will NOT be sent by mail',
        email: 'Your email address',

        chooseContactFrame: {
            pickYourCaseworker: 'Pick your caseworker',
            pickExcludedCaseworker: 'Caseworkers that will receive the report',
            theContactWill: 'The chosen contact will receive the report',
            theContactWillNot: 'Caseworkers that will receive the report',
            languages: 'Languages',
            languagesNotGiven: 'Languages not given',
            pickCaseworker: 'Pick caseworker',
        },
        popUpRememberYourWhistleblowerCode: 'Remember your Whistleblower Code',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'It is important that you save and store the whistleblower code, so you will be able to access the case in the future.',
    },
    accessCaseFlow: {
        date: 'Date',
        files: 'Files',
        accessExistingReport: 'Access existing report',
        inOrderToAccessYourCase:
            'Use the received Whistleblower Code to access your existing report.',
        missingCode: 'Lost your code?',
        createNewRaport: 'Please create a new report.',
        enterYourWhistleblowerCode: 'Enter your Whistleblower Code',
        enterCode: 'Enter code',
        logout: 'Log out',
        info: 'Info',
        id: 'ID',
        appearance: 'Appearance',
        caseworkers: 'Caseworkers',
        fromWhistleblower: 'From whistleblower',
        fromCaseworker: 'From caseworker',
        timeline: 'Timeline',
        description: 'Description',
        messages: 'Messages',
        translate: 'Translate',
        translated: 'Translated',
        anonymous: 'Anonymous',
        enterMessage: 'Enter message',
        send: 'Send',
        logoutOfReport: 'Logout of report',
        hiddenMsgOne: 'This is the date on which the report was created',
        hiddenMsgTwo: `This is the deadline for the caseworker's initial reply to you`,
        hiddenMsgThree: `This is the deadline for the caseworker's feedback to you`,

        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },

    header: {
        dashboard: 'Dashboard',
        cases: 'Cases',
        clients: 'Clients',
        notifications: 'Notifications',
        readAll: 'Read all',
    },
    dashboardPartner: {
        headlineDashboard: 'Dashboard',
        yourcases: 'Your Cases',
        headline: 'Headline',
        notifications: 'Notifications',
        all: 'All',
        unread: 'Unread',
        noCases: 'You have no cases',
        noNotifications: 'You have no notifications',
        yourStatistics: {
            yourStatistics: 'Your statistics',
            open: 'Open',
            closed: 'Closed',
            forwarded: 'Forwarded',
            totalCases: 'Total cases',
            activeCases: 'Active cases',
            closedCases: 'Closed cases',
            forwardedCases: 'Forwarded cases',
        },
    },
    casesPartner: {
        headlineCases: 'Cases',
        allCases: 'All cases',
        headline: 'Headline',
        status: 'Status',
        filter: 'Filters',
        removeFilter: 'Remove filters',
        myCases: 'My cases',
        actionRequired: 'Action required',
        awaitingResponse: 'Awaiting response',
        seeClosedCases: 'View closed cases',
        viewForwardedCases: 'View forwarded cases',
        forwardedCases: 'Forwarded cases',
        searchCases: 'Search for cases',
        headlineClosedCases: 'Closed cases',
        closedCases: 'Closed cases',
    },
    caseworkerOpenReport: {
        transcription: 'Transcription',
        goBack: 'Go back',
        reportBy: 'Report by',
        days: 'days',
        deadlineExceededTagOne: 'Deadline exceeded by',
        deadlineExceededTagTwo: 'Legal deadline met',
        hours: 'hours',
        thisReportHasBeen: 'This report has been sent by',
        sevenDaysMessageOne:
            'You must provide an acknowledgment of the receipt of a report within 7 days of receiving it.',
        sevenDaysMessageTwo:
            'Send a message to the whistleblower below and comply with the law.',
        ninetyDaysMessageOne:
            'The whistleblower should receive feedback as soon as possible and no later than 3 months from the acknowledgement of the receipt of the report.',
        ninetyDaysMessageTwo:
            'For example, feedback may be the plan for or result of an internal investigation.',
        exportCase: 'Export',
        editInformation: 'Edit information',
        changeResponsibility: 'Change responsible',
        theNewResponsible: 'The new responsible will be notified by e-mail',
        thereAreNoOther: 'There are no other caseworkers available',
        forward: 'Forward',
        title: 'Title',
        uncategorized: 'Uncategorized',
        categorizeCase: 'Categorize case',
        selectCategory: 'Select a category for this case',
        selectedCategory: 'Selected category',
        close: 'Close',
        saveThis: 'Save',
        frequentCategories: 'Frequent categories',
        allCategories: 'All categories',
        ViewMoreCategories: 'View more catepories',
        ViewLess: 'View less',
        status: 'Status',
        changeStatus: 'Change status',
        youAreInTheProcess: 'You are in the process of changing the status to',
        awaitingResponse: 'Awaiting response',
        actionRequired: 'Action required',
        confirm: 'Confirm',
        caseDescription: 'Description',
        noFilesUploaded: 'No files uploaded',
        historyLog: 'History log',
        preferredContact: 'Preferred contact',
        noMessages: 'No messages',
        internalNotes: 'Internal notes',
        takeNotesHere: 'Add your notes here, so you do not forget',
        onlyTheCaseworkers: 'This is only visible to the caseworkers',
        enterNote: 'Write note',
        saveNote: 'Save',
        closeCase: 'Close case',
        solved: 'Solved',
        denied: 'Rejected',
        forwarded: 'Forwarded',
        addComment: 'Add comment',
        forwardedAt: 'Forwarded at',
        severityScore: 'Severity score',
        comment: 'Comment',
        theCaseHasBeenForwardedTo: 'The case has been forwarded to',
        theCaseHasBeenForwardedBy: 'The case has been forwarded by',
        caseClosed: 'Case closed',
        toEnsureGDPR:
            'To ensure GDPR compliance, this case will be permanently deleted in',
        theOptionToExportTheCase:
            'The option to export the case to a PDF is available within this timeframe.',
        theDeletionOfTheCaseWillNot:
            'The deletion of the case will not affect your statistics overview.',

        caseworkerPopup: {
            editCaseworker: 'Edit caseworker',
            close: 'Close',
            caseworkerInCharge: 'In charge of the case',
            newFlag: 'New',
            addCaseworkers: 'Add caseworkers',
            noCaseworkerAvailable:
                'There are no other avialable caseworker to be assigned to this case',
            discardChanges: 'Discard changes',
        },
    },

    caseClosedViewPartner: {
        closeDate: 'Close date',
        comment: 'Comment',
        readMore: 'Read more',
        noComment: 'No comment',
        automaticDelation: 'Automatic deletion',
        exportCase: 'Export case',
        caseWillBePermanentlyDeleted:
            'For GDFR compliance, this case will be permanently deleted',
    },
    clientsPartner: {
        headlineClients: 'Clients',
        buttonAddNewClient: 'Add new client',
        allClients: 'All clients',
        openCases: 'Open cases',
        closeCases: 'Closed cases',
        newCases: 'New cases',
    },
    singleClientViewPartner: {
        information: 'Information',
        lineOfContact: 'Line of contact',
        cases: 'Cases',
        buttonSave: 'Save',
        saveOrderOfContacts: 'Save order of contacts',
        lineOfContactsHaveNot: 'Line of contacts have not been created',
        deleteCaseworker: 'Delete caseworker',
        youAreAboutToDeleteTheCaseworker:
            'You are about to delete the caseworker',
        theCaseworkerWillNotAbleToReceive:
            'The caseworker will not longer be able to receive future reports',
        theCaseworkerCannotBeDeletedIf:
            'The caseworker cannot be deleted if their associated to an open case',
        thisActionCannotBeUndone: 'This action cannot be undone',
        caseworkerHasCasesAndCannotBeDeleted:
            'The caseworker has one or more active cases and cannot be deleted',
        contactAdded: 'A new caseworker has been added',
        contactUpdated: 'Caseworker updated',
        inviteCaseworker: 'Invite caseworker',
        allCases: 'All cases',
        company: 'Company',
        headline: 'Headline',
        status: 'Status',
        actionRequired: 'Action required',
        awaitingResponse: 'Awaiting response',
        reOpenCase: 'Reopen case',
        youAreInTheProcessOfExporting:
            'You are in the process of exporting case',
        youAreInTheProcessOfReopening:
            'You are in the process of reopening case',
        caseInfo: 'Case info',
        arrivalDate: 'Arrival date',
        closeDate: 'Close date',
        caseID: 'Case-ID',
        theInternalInvestigationHasConcluded:
            'The internal investigation has concluded and the case will be deleted in',
        addLanguage: 'Add language',
        searchLanguage: 'Search language',
        languageNotFound: 'Language not found',
    },
    addNewClientPartner: {
        headlineAddNewClient: 'Add new client',
        buttonCancel: 'Cancel',
        searchForCompanyNameOrCompanyNumber:
            'Search for company name or company number',
        findCompany: 'Find company',
        enterInformationManually: 'Enter information manually',
        thanksForYourSubscription: 'You have added a new client',
        informationOnHowToGetStarted:
            'Information on how the client can get started',
        linkText:
            'The link will provide you with the access to the digital whistleblower reporting channel where the reports can be created and submitted. Share this with the client.',
        employeeLink: 'Employee link',
        whistleblowerPolicyText:
            'Find the client’s whistleblower policy below and refer the client to login to the platform for additional material.',
        whistleblowerPolicy: 'Whistleblower policy',
        download: 'Download',
        sendLinkText: 'Send the information to your client',
        buttonSendEmail: 'Send email',
        popUpSendEmail: 'Send email',
        popUpText:
            'Your are about to send the Whistleblower reporting channel link & Whistleblower Policy to',
        popUpButtonSendInformation: 'Send information',
        popUpAreYouSureYouWantToCancelThisSession:
            'Are you sure, that you want to cancel this session?',
        popUpYouWillLoseAllYourProgress: 'You will lose all your progress',
        popUpButtonNo: 'No',
        popUpButtonYes: 'Yes',
    },
    settingsPartner: {
        headlineSettings: 'Settings',
        myAccount: 'My account',
        automaticReply: 'Automatic reply',
        billing: 'Billing',
        users: 'Users',
        logOut: 'Log out',
        edit: 'Edit',
        changePassword: 'Change password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        passwordUpdate: 'Done!',
        passwordUpdadetSuccessfully: 'Password updated successfully.',
        defaultLanguage: 'Default language',
        emailUpdated: 'Email Updated!',
        invoices: 'Invoices',
        verificationLinkSent:
            'We have sent a verification link to the new email. You will be logged out in',
        seconds: 'seconds.',
        reactivateAutoReply: 'Reactivate automatic reply',
        active: 'Active',
        inactive: 'Inactive',
        autoReplyAlertMessage:
            'The automatic reply will be sent 15 minutes after receiving the report',
        added: 'Added',
        openCases: 'Open cases',
        closedCases: 'Closed cases',
        buttonAddNewUser: 'Add new user',
        popUpAddingANewUser: 'Adding a new user',
        popUpAddPhoto: 'Add photo',
        popUpChangePhoto: 'Change photo',
        popUpText:
            'The user has to verify their account by an activation email',
        popUpButtonAddUser: 'Add user',
        myAccountFrame: {
            somethingWentWrong: 'Something went wrong. Please try again.',
            yourChangesHaveBeen: 'Your changes have been saved successfully',
        },

        oldAddPaymentMethodFrame: {
            paymentMethods: 'Billing',
            addPaymentMethod: 'Add payment method',
            defaultPaymentMethod: 'Default payment method',
            yourDefaultPaymentMethod:
                'The default payment method will be used for all future payments. If you have a subscription fee due we will attempt to charge it automatically.',
            defaultPaymentMethodMissing: 'Default payment is missing',
            otherPaymentMethods: 'Other payment methods',
            otherPaymentMethodsYouHave:
                'Other payment methods you have added. Mark the one you wish to use as default.',
            currentPlan: 'Current plan',
            belowYouCanSee: 'Here you can see the selected subscription plan.',
            cardNumber: 'Card number',
            expireDate: 'Expire date',

            addPaymentMethodModal: {
                addPaymentMethod: 'Add payment method',
                cancel: 'Cancel',
                card: 'Card',
                invoice: 'Invoice',
                errorLoadingStripe: 'Error, loading Stripe',
                fullName: 'Full name',
                email: 'Email',
                address: 'Address',
                city: 'City',
                zipCode: 'Zip code',
                bankInformation: 'Bank Information',
                cardInformation: 'Card information',
                loginMessage:
                    'This might take a few minutes. Please, be patient.',
            },
        },
        addPaymentMethodFrame: {
            creditOrDebit: 'Credit or debit card',
            invoice: 'Invoice',
            addACard: 'Add a card as your payment method',
            useAnInvoice: 'Use an invoice as your payment method',
            active: 'Active',
            freeTrial: 'Free trial',
            canceled: 'Canceled',
            manageSubscription: 'Mange subscription',
            addPaymentMethod: 'Add payment method',
            reactiveSubscription: 'Reactivate subscription',
            cancelSubscription: 'Cancel subscription',
            youCanCancel:
                'You can cancel your subscription at any time. You will continue to have access to your account until your current billing period ends.',
            needHelp: 'Need help',
            areYouSure: 'Are you sure you want to cancel your subscription',
            pleaseLetUsKnow:
                "Please let us know if there's a specific concern we can address before you leave.",
            ifYourCertain:
                "If you're certain about canceling, we'll be here for you whenever you're ready to return.",
            noGoBack: 'No, go back',
            itIsToo: 'It is too expensive',
            weDidNotUse: 'We did not use the platform',
            other: 'Other',
            whyAreYouCanceling: 'Why are you canceling',
            yourSubscriptionHasBeen: 'Your subscription has been canceled',
            yourPlanIsActiveUntil: 'Your plan is active until',
            youWillContinue:
                'You will continue to have access to your account until then.',
            editPaymentMethod: 'Edit payment method',
            currentPaymentMethods: 'Current payments methods',
            chooseAnotherMethod: 'Choose another method',
            paymentFailed: 'Payment failed',
            yourPaymentHasFailed: 'Your payment has failed',
            theInvoiceHasNotBeenPayed: 'The invoice has not been payed',
            doYouNeedHelp: 'Do you need help',
            weWillGetInContact:
                'We will get in contact with you within 2 hours.',
            talkToYouSoon: 'Talk to you soon',
            getBack: 'Get back',
            submit: 'Submit',
            day: 'Day',
            days: 'Days',
            yourCardHasExpired: 'Your card has expired',
            pdf: 'PDF',
            whistleblowingSoftware: 'Whistleblowing software',
            amount: 'Amount',
            back: 'Back',
            subscription: 'Subscription',
            chooseThePlanThat:
                "  Choose the plan that matches your organization's total headcount",
            employees: 'Employees',
            year: 'year',
            currentPlan: 'Current plan',
            newPlan: 'New plan',
            choosePlan: 'Choose plan',
        },
        invoicesFrame: {
            billingHistory: 'Billing history',
            invoice: 'invoice',
            amount: 'Amount',
            plan: 'Plan',
            status: 'Status',
            paid: 'Paid',
            waitingToPay: 'Waiting to pay',
            download: 'Download',
        },
        userInformation: 'User information',
    },
    clientView: {
        information: 'Information',
        lineOfContact: 'Caseworkers',
        customization: 'Customization',
        whistleblowerPolicy: 'Whistleblower Policy',
        reportingChannel: 'Reporting channel',
        copyLinkReportingChannel: 'Copy link to your reporting channel',
        downloadQRCodeImage: 'Download image here',
        downloadQRCode: 'Download reporting channel QR-code',
        copyLinkWhistleblowerPolicy: 'Copy link to your whistleblower policy',
        reportingChannelLanguage: 'Reporting channel language:',
        reportingChannelLanguageError: 'Reporting channel language error:',
        qrCode: 'QR-Code',
        trainingMaterial: 'Training Material',
        copyLinkToTrainingMaterial: 'Copy link to your training material',

        workEmail: 'Work email',
        phone: 'Phone',
        address: 'Address',
        numberOfEmployees: 'Number of employees',
        contactPerson: 'Contact person',
        willBeDisplayedReportLink:
            'The information below will be displayed to the whistleblower in the reporting link',
        languages: 'Languages',
        notes: 'Notes',
        preview: 'Preview',
        pickYourCaseworker: 'Pick your caseworkers',
        theContactWill: 'The chosen caseworkers will receive the report',
        note: 'Note',
        editProfilePicture: 'Edit profile picture',
        addPhoto: 'Add photo',
        changePhoto: 'Change photo',
        addQuestion: 'Add Question',
        reportQuestions: 'Report Questions',
        addAQuestion: 'Add your own questions',
        questionOnReportingChannel: 'Questions on your reporting channel',
        dragToChangeOrderOfQuestions:
            'Drag and change the order of the questions here.',
        saveOderOfQuestions: 'Save order of questions',
        industrySuggestedQuestionTitle:
            'Here are some recommended questions specific to your industry.',
        suggestedQuestionText:
            'These quotations are based on your industry. Use them, get inspired or just leave them out.',
    },
    verifyEmail: {
        verifyEmail: 'Verify email',
        emailVerified: 'Email verified',
        yourEmailHasBeen:
            'Your email has been verified, you can now go to the login page and login with your email and newly created password',
        goToLogin: 'Go to login',
        verifyNewEmail: 'Verified!',
        loginWithNewEmail: 'You can now login with the new email.',
    },
    nextButton: {
        nextStep: 'Next',
    },
    finishButton: {
        finish: 'Finish',
    },
    closeButton: {
        close: 'Close',
    },
    dropdown: {
        countryNotFound: 'Country not found',
    },
    copiedText: {
        copied: 'Copied',
    },
    you: {
        you: 'You',
    },
    warningText: {
        warning: 'Warning',
    },
    deleteText: {
        delete: 'Delete',
    },
    inactiveModal: {
        inactivityWarning: 'Inactivity warning',
        youWillBeLoggedOut: 'You will be logged out',
        youHaveBeenInactive:
            'You have been inactive for 14 minutes. When the timer runs out you will be logged out',
        stayLoggedIn: 'Stay logged in',
    },
    notifications: {
        newMessage: 'New message',
        deadlineAlert: 'Deadline Alert',
        newReport: 'New report',
        reportAssigned: 'Report assigned',
        reportUnassigned: 'Report unassigned',
    },
    historyLog: {
        forwarded: 'Forwarded',
        exported: 'Exported',
        changeResponsible: 'Change responsible',
        caseViewed: 'Case viewed',
        fileUploaded: 'File uploaded',
        newMessage: 'New message',
        statusUpdated: 'Status updated',
        caseDelegation: 'Case delegation',
        reportCreated: 'Report created',
        to: 'to',
        by: 'by',
        from: 'from',
        caseHasBeenForwarded: 'Case has been forwarded',
        caseHasBeenExported: 'Case has been exported',
        caseHasBeenReassigned: 'Case has been reassigned',
        caseHasBeenViewed: 'Case has been viewed',
        categoryIsChanged: 'Category is changed',
        messageFrom: 'Message from',
        caseAssigned: 'Case assigned',
        newCase: 'New case',
        caseClosed: 'Case was closed',
        caseClosedBy: 'This case was closed by',
        caseReopened: 'Case was reopened',
        caseReopenedBy: 'This case was reopened by',
        internalNote: 'Internal note',
        internalNoteHasBeen: 'Internal note has been written by',
        caseworkerAdded: 'Caseworker added',
        caseworker: 'Caseworker',
        wasAddedBy: 'was added by',
        newFile: 'New file',
        newFileWasAdded: 'A new file was added by',
        whistleblower: 'whistleblower',
        automaticReplySent: 'Automatic reply sent',
        automaticReplyWasSent: 'Automatic reply was sent by',
    },
    validation: {
        nameOfTheCityMustBeAtLeast:
            'Name of the city must be at least 1 character',
        nameOfTheCityCannot:
            'Name of the city cannot be longer then 85 characters',
        companyIdentificationNumberMust:
            'Number must be exactly 8 characters long',
        addressIsToShort: 'Address is to short',
        addressIsToLong: 'Address is to long',
        organizationIsToShort: 'Organization is to short',
        organizationIsToLong: 'Organization is to long',
        mustBeAValidEmail: 'Must be a valid email address',
        firstNameCannotBeShorter:
            'First name cannot be short then 2 characters',
        firstNameCannotBeLonger:
            'First name cannot be longer then 26 characters',
        lastNameCannotBeShorter: 'Last name cannot be short then 2 characters',
        lastNameCannotBeLonger: 'Last name cannot be longer then 26 characters',
        passwordMustBeAtLeast: 'Password must be at least 8 characters',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Your password must contain at least one uppercase letter',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Your password must contain at least one lowercase letter',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Your password must contain at least one symbol letter',
        yourPasswordMustContainerAtLeastOneNumber:
            'Your password must contain at least one number letter',
        passwordsMustMatch: 'Passwords must match',
        notAValidPhoneNumber: 'Not a valid phone number',
        titleOfPositionMustBe:
            'Title of position must be longer then 2 characters',
        titleOfPositionCannotBe:
            'Title of position cannot be longer then 26 characters',
        mustSetAPriority: 'You must set a priority',
        notAValidZipCode: 'Not a valid zip code',
        isLongEnough: 'At least 8 characters long',
        hasUpperCaseLetter: 'At least 1 upper case letter [A-Z]',
        hasLowerCaseLetter: 'At least 1 lower case letter [a-z]',
        hasNumber: 'At least 1 number [0-9]',
        hasSpecialCharacter: 'At least 1 special character[!@#$%^&*()\\-__+.]',
        passwordsMatch: 'Both passwords match',
    },
    forwardReportPage: {
        selectCatagory: 'Select category',
        uncategorized: 'Uncategorized',
        accusationAndEvidenceScore: 'Accusation and evidence score',
        accusationScore: 'Accusation score',
        evidenceScore: 'Evidence score',
        severityScore: 'Total severity score',
        lineOfContact: 'Line of contact',
        lineOfContactReminder:
            'Please make sure that the person is by no means implicated in this case',
        comment: 'Comment',
        pleaseLeaveComment: 'Please leave a comment here',
        forwardCase: 'Forward case',
        popUpConfirmForwardPartOne: 'Are you sure that you want to forward',
        popUpConfirmForwardPartTwo: 'to',
        popUpNo: 'No',
        popUpYes: "Yes, i'm sure",
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Max',
        saveThis: 'Save this',
    },
    trialWarning: {
        goToSettings: 'Go to settings',
        yourTrialHasEnded: 'Your trial has ended.',
        yourFreeTrialEndedOn: 'Your free trial ended on:',
        yourFreeTrialEndsOn: 'Trial expires in',
        day: 'day',
        days: 'days',
    },
    categories: {
        accounting_irregularities: 'Accounting irregularities',
        bribery: 'Bribery',
        consumer_protection: 'Consumer protection',
        duty_of_confidentiality: 'Duty of confidentiality',
        embezzlement: 'Embezzlement',
        extortion: 'Extortion',
        financial_services_products_and_markets:
            'Financial services, products and markets',
        food_safety_animal_health_and_welfare:
            'Food safety, animal health and welfare',
        forgery: 'Forgery',
        fraudulence: 'Fraudulence',
        money_laundering_and_terrorist_financing:
            'Money laundering and terrorist financing',
        occupational_health_and_safety: 'Occupational health and safety',
        products_safety_and_compliance: 'Products safety and compliance',
        protection_of_environment: 'Protection of environment',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Protection of privacy and personal data and security of network and information systems',
        public_health: 'Public health',
        public_procurement: 'Public procurement',
        radiation_protection_and_nuclear_safety:
            'Radiation protection and nuclear safety',
        serious_or_recurring_conflicts_at_the_workplace:
            'Serious or recurring conflicts at the workplace',
        sexual_harassment_and_sexual_assault:
            'Sexual harassment and sexual assault',
        the_financial_interests_of_the_european_union:
            'The financial interests of the European Union',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'The internal market of the European Union, including state aid and corporate taxation',
        theft: 'Theft',
        conflict_of_interest: 'Conflict of interest',
        transport_safety: 'Transport safety',
        non_whistleblower_case: 'Non whistleblower case',
    },
    integrationModal: {
        thisAppWouldLikeToAccess:
            'This app would like to access your Walor account. You need to give permission to do this',
        thisAppRequires: 'This app requires permission to',
        whistleblowingChannel: 'whistleblowing channel',
        whistleblowingPolicy: 'whistleblowing policy',
        employeeGuides: 'employee guides',
        companyName: 'company name',
        approve: 'Approve',
    },
    dacarpoPolicy: {
        itIsImportant:
            'It is important that you save the whistleblower code from the report to access the case afterwards.',
        allQuestions:
            'All questions about the whistleblower scheme can be directed to our HR department.',
        youWillReceive:
            'You will receive an acknowledgement of receival of the report within seven days.',
        afterThis:
            'After this, an initial examination of the report will be conducted.',
        ifTheReport:
            'If the report turns out to be notably unfounded, it will be rejected. The person who reported the case will be notified of this as soon as possible',
        howeverIfTheInitial:
            'However, if the initial examination determines that the report cannot be characterized as notably unfounded, it will be further investigated.',
        theCaseWillThen:
            'The case will then be processed internally. The whistleblower will receive feedback on any updates or outcomes as soon as possible.',
        thisInternalInvestigation:
            'This internal investigation may have consequences for the person or persons who are involved.',
        ifTheCaseIs:
            'If the case is of particular serious nature, it may also be passed on to the police for further investigation',
        afterConducting:
            'After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
    },
    inviteCaseworkerModal: {
        inviteNewCaseworker: 'Invite a new caseworker',
        sendInvitation: 'Send invitation',
    },
    caseworkerSetupAccountPage: {
        setupYourWalor: 'Set up your Walor account',
        youHaveBeenInvited: 'You have been invited to join',
        onWalorAs: 'on Walor as a',
        caseworker: 'caseworker',
        personalInformation: 'Personal information',
        additionalInformation: 'Additional information',
        preview: 'Preview',
        profilePicture: 'Profile picture',
        thisPictureWillAppear:
            'This picture will appear when the whistleblower selects caseworkers.',
        addPicture: 'Add picture',
        notes: 'Notes',
        typeNoteHere: 'Type a note here..',
        addLanguages: 'Add languages',
        languages: 'Languages',
        whichDepartmentAreYou: 'Which department are you a part of',
        chooseOneOption: 'Choose one option',
        humanResources: 'Human Resources',
        compliance: 'Compliance',
        finance: 'Finance',
        operations: 'Operations',
        marketing: 'Marketing',
        other: 'Other',
        pickCaseworker: 'Pick caseworker',
        notesHover: 'The notes will displayed to the whistleblower',
        languagesHover:
            'Specify the languages you speak so the whistleblower can choose the best suited caseworker',
    },
    aalborgTeaterPolicy: {
        whistleblowerSchemeEnsuresProtection:
            "'s whistleblower scheme ensures protection for individuals who report serious offenses and matters. This scheme can be used if you experience, are aware of or have reasons to suspect the occurrence of such breaches at",
        theWhistleblowerSchemeCanBeUsed:
            'The whistleblower scheme can be used to address any information about serious violations of the law, attempts to conceal such violations or other serious matters that have occurred or are likely to occur at',
        aReportCanBeMadeEither:
            'A report can only be confidential, which means that the whistleblower discloses personal information. If personal information is shared in your report, only those who are authorised to investigate the case will have access thereto. The confidentiality of the submitted report will therefore in any case be respected.',
    },
    reusable: {
        voiceRecording: 'Voice recording',
        email: 'Email',
        personalInformation: 'Personal Information',
        firstName: 'First name',
        lastName: 'Last name',
        cancel: 'Cancel',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',
        country: 'Country',
        numberOfEmployees: 'Number of employees',
        phone: 'Phone number',
        position: 'Position',
        companyInformation: 'Company information',
        companyNumber: 'Company number',
        companyName: 'Company name',
        workEmail: 'Work email',
        enterWorkEmail: 'Work email',
        password: 'Password',
        anonymous: 'Anonymous',
        files: 'Files',
        date: 'Date',
        messages: 'Messages',
        organization: 'Organization',
        new: 'New',
        search: 'Search',
        category: 'Category',
        save: 'Save',
        confirmPassword: 'Confirm password',
        name: 'Name',

        closed: 'Closed',
        archived: 'Archived',
    },

    customizeWhistleblowerPolicy: {
        whistleblowerPolicy: 'Whistleblower Policy',
        whistleBlowerPolicyLanguages: 'Whistleblower Policy Languages',
        reviseTheDefault:
            'Revise the default policy before adding translations',
        addLanguage: 'Add language',
        selectALanguage: 'Select a language',
        translations: 'Translations',
        default: 'Default',
        deleteLanguage: 'Delete language',
        youAreAboutToDelete:
            'You are about to delete the whistleblowing policy language',
        noGoBack: 'No, go back',
        restoreDefaultPolicy: 'Restore default policy',
        youAreAbout:
            'You are about to restore the default whistleblowing policy!',
        anyModifications:
            'Any modifications made to the English translation wil be overridden',
        restoreToDefault: 'Restore to default',
        youHaveTheOption:
            'You have the option to make corrections in your whistleblower policy here. As you make changes from the default policy, you need translate the policy in the languages you’d like to support. You can use the automatic translator tool below and edit this.',
        autoTranslate: 'Auto translate',
        addMoreLanguages: 'Add more languages',
    },
    clientsPage: {
        clients: 'Client',
        allClients: 'All clients',
        addClient: 'Add client',
    },

    statisticsPage: {
        statistics: 'Statistics',
        overview: 'Overview',
        compareToLastPeriod: 'Compare to last period',
        noData: 'No data',
        lastYear: 'last year',
        anonymousVsConfidential: 'Anonymous vs. Confidential',
        actionStats: 'Action stats',
        avgDaysToFirstResponse: 'Avg. days to first response',
        avgDaysToClosedCase: 'Avg. days to closed case',
        distributionOfCategories: 'Distribution of categories',
        casesByMonth: 'Cases by month',
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
    },
}

export default en
